const Toolbox = {
  kind: "categoryToolbox",
  autoClose: false,
  contents: [
    {
      kind: "category",
      name: "Logic",
      categorystyle: "logic_category",
      contents: [
        {
          kind: "block",
          type: "controls_if",
        },
        {
          kind: "block",
          type: "logic_compare",
        },
        {
          kind: "block",
          type: "variable_comparator",
        },
        {
          kind: "block",
          type: "logic_operation",
        },
        {
          kind: "block",
          type: "logic_negate",
        },
        {
          kind: "block",
          type: "logic_boolean",
        },
        {
          kind: "block",
          type: "logical_and_plus_minus",
        },
        {
          kind: "block",
          type: "logical_or_plus_minus",
        },
        {
          kind: "block",
          type: "logic_null",
        },
        {
          kind: "block",
          type: "logic_ternary",
        },
      ],
    },
    {
      kind: "category",
      name: "Loops",
      categorystyle: "loop_category",
      contents: [
        // {
        //   kind: "block",
        //   type: "controls_repeat_ext",
        //   inputs: {
        //     TIMES: {
        //       block: {
        //         type: "math_number",
        //         fields: {
        //           NUM: 10,
        //         },
        //       },
        //     },
        //   },
        // },
        {
          kind: "block",
          type: "loop_number",
        },
        {
          kind: "block",
          type: "loop_variable",
        },
        {
          kind: "block",
          type: "loop_forever",
        },
        {
          kind: "block",
          type: "controls_whileUntil",
        },
        {
          kind: "block",
          type: "controls_until_multiprocessing",
        },
        {
          kind: "block",
          type: "controls_for",
          fields: {
            VAR: "i",
          },
          inputs: {
            FROM: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
            BY: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "controls_forEach",
        },
        {
          kind: "block",
          type: "controls_flow_statements",
        },
      ],
    },
    {
      kind: "category",
      name: "Text",
      categorystyle: "text_category",
      contents: [
        {
          kind: "block",
          type: "text",
          fields: {
            TEXT: " ",
          },
        },
        {
          kind: "block",
          type: "text_print",
          inputs: {
            TEXT: {
              block: {
                type: "text",
                fields: {
                  TEXT: "Your text!",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_join",
        },
        {
          kind: "block",
          type: "text_append",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_length",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_isEmpty",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_indexOf",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
            FIND: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_charAt",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_getSubstring",
          inputs: {
            STRING: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_changeCase",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_trim",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_count",
          inputs: {
            SUB: {
              shadow: {
                type: "text",
              },
            },
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_replace",
          inputs: {
            FROM: {
              shadow: {
                type: "text",
              },
            },
            TO: {
              shadow: {
                type: "text",
              },
            },
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_reverse",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "spacer",
        },
        {
          kind: "block",
          type: "comment",
        },
        {
          kind: "block",
          type: "log",
        },
        {
          kind: "block",
          type: "write_to_sd",
        },
      ],
    },
    {
      kind: "category",
      name: "Math",
      categorystyle: "math_category",
      contents: [
        {
          kind: "block",
          type: "math_number",
          fields: {
            NUM: 123,
          },
        },
        {
          kind: "block",
          type: "math_arithmetic",
          fields: {
            OP: "ADD",
          },
        },
        {
          kind: "block",
          type: "math_single",
          fields: {
            OP: "ROOT",
          },
        },
        {
          kind: "block",
          type: "math_trig",
          fields: {
            OP: "SIN",
          },
        },
        {
          kind: "block",
          type: "math_constant",
          fields: {
            CONSTANT: "PI",
          },
        },
        {
          kind: "block",
          type: "constrain",
        },
        {
          kind: "block",
          type: "math_number_property",
          extraState: '<mutation divisor_input="false"></mutation>',
          fields: {
            PROPERTY: "EVEN",
          },
        },
        {
          kind: "block",
          type: "math_round",
          fields: {
            OP: "ROUND",
          },
        },
        {
          kind: "block",
          type: "math_on_list",
          extraState: '<mutation op="SUM"></mutation>',
          fields: {
            OP: "SUM",
          },
        },
        {
          kind: "block",
          type: "math_modulo",
        },
        {
          kind: "block",
          type: "math_constrain",
          inputs: {
            LOW: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            HIGH: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_int",
          inputs: {
            FROM: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_float",
        },
      ],
    },
    {
      kind: "category",
      name: "Lists",
      categorystyle: "list_category",
      contents: [
        {
          kind: "block",
          type: "lists_create_empty",
        },
        {
          kind: "block",
          type: "lists_create_with",
          extraState: {
            itemCount: 3,
          },
        },
        {
          kind: "block",
          type: "lists_repeat",
          inputs: {
            NUM: {
              block: {
                type: "math_number",
                fields: {
                  NUM: 5,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_length",
        },
        {
          kind: "block",
          type: "lists_isEmpty",
        },
        {
          kind: "block",
          type: "lists_indexOf",
          fields: {
            END: "FIRST",
          },
        },
        {
          kind: "block",
          type: "lists_getIndex",
          fields: {
            MODE: "GET",
            WHERE: "FROM_START",
          },
        },
        {
          kind: "block",
          type: "lists_setIndex",
          fields: {
            MODE: "SET",
            WHERE: "FROM_START",
          },
        },
      ],
    },
    {
      kind: "sep",
    },
    {
      kind: "category",
      name: "Variables",
      categorystyle: "variable_category",
      custom: "VARIABLE",
    },
    // {
    //   "kind":"category",
    //   "name":"Variables",
    //   "categorystyle":"variable_category",
    //   "custom":"VARIABLE_DYNAMIC"
    // },
    {
      kind: "category",
      name: "Time",
      categorystyle: "variable_category",
      custom: "TIMER",
    },
    {
      kind: "category",
      name: "Functions",
      categorystyle: "procedure_category",
      custom: "PROCEDURE",
    },

    {
      kind: "category",
      name: "GPIO",
      colour: 20,
      custom: "GPIO",
    },
    /*{
      kind: "category",
      name: "Library",
      expanded: "true",
      contents: [
        {
          kind: "category",
          name: "Randomize",
          contents: [
            {
              kind: "block",
              type: "procedures_defnoreturn",
              extraState: {
                params: [
                  {
                    name: "list",
                  },
                ],
              },
              icons: {
                comment: {
                  text: "Describe this function...",
                  pinned: false,
                  height: 80,
                  width: 160,
                },
              },
              fields: {
                NAME: "randomize",
              },
              inputs: {
                STACK: {
                  block: {
                    type: "controls_for",
                    fields: {
                      VAR: {
                        name: "x",
                      },
                    },
                    inputs: {
                      FROM: {
                        block: {
                          type: "math_number",
                          fields: {
                            NUM: 1,
                          },
                        },
                      },
                      TO: {
                        block: {
                          type: "lists_length",
                          inline: false,
                          inputs: {
                            VALUE: {
                              block: {
                                type: "variables_get",
                                fields: {
                                  VAR: {
                                    name: "list",
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      BY: {
                        block: {
                          type: "math_number",
                          fields: {
                            NUM: 1,
                          },
                        },
                      },
                      DO: {
                        block: {
                          type: "variables_set",
                          inline: false,
                          fields: {
                            VAR: {
                              name: "y",
                            },
                          },
                          inputs: {
                            VALUE: {
                              block: {
                                type: "math_random_int",
                                inputs: {
                                  FROM: {
                                    block: {
                                      type: "math_number",
                                      fields: {
                                        NUM: 1,
                                      },
                                    },
                                  },
                                  TO: {
                                    block: {
                                      type: "lists_length",
                                      inline: false,
                                      inputs: {
                                        VALUE: {
                                          block: {
                                            type: "variables_get",
                                            fields: {
                                              VAR: {
                                                name: "list",
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                          next: {
                            block: {
                              type: "variables_set",
                              inline: false,
                              fields: {
                                VAR: {
                                  name: "temp",
                                },
                              },
                              inputs: {
                                VALUE: {
                                  block: {
                                    type: "lists_getIndex",
                                    fields: {
                                      MODE: "GET",
                                      WHERE: "FROM_START",
                                    },
                                    inputs: {
                                      VALUE: {
                                        block: {
                                          type: "variables_get",
                                          fields: {
                                            VAR: {
                                              name: "list",
                                            },
                                          },
                                        },
                                      },
                                      AT: {
                                        block: {
                                          type: "variables_get",
                                          fields: {
                                            VAR: {
                                              name: "y",
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                              next: {
                                block: {
                                  type: "lists_setIndex",
                                  inline: false,
                                  fields: {
                                    MODE: "SET",
                                    WHERE: "FROM_START",
                                  },
                                  inputs: {
                                    LIST: {
                                      block: {
                                        type: "variables_get",
                                        fields: {
                                          VAR: {
                                            name: "list",
                                          },
                                        },
                                      },
                                    },
                                    AT: {
                                      block: {
                                        type: "variables_get",
                                        fields: {
                                          VAR: {
                                            name: "y",
                                          },
                                        },
                                      },
                                    },
                                    TO: {
                                      block: {
                                        type: "lists_getIndex",
                                        fields: {
                                          MODE: "GET",
                                          WHERE: "FROM_START",
                                        },
                                        inputs: {
                                          VALUE: {
                                            block: {
                                              type: "variables_get",
                                              fields: {
                                                VAR: {
                                                  name: "list",
                                                },
                                              },
                                            },
                                          },
                                          AT: {
                                            block: {
                                              type: "variables_get",
                                              fields: {
                                                VAR: {
                                                  name: "x",
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                  next: {
                                    block: {
                                      type: "lists_setIndex",
                                      inline: false,
                                      fields: {
                                        MODE: "SET",
                                        WHERE: "FROM_START",
                                      },
                                      inputs: {
                                        LIST: {
                                          block: {
                                            type: "variables_get",
                                            fields: {
                                              VAR: {
                                                name: "list",
                                              },
                                            },
                                          },
                                        },
                                        AT: {
                                          block: {
                                            type: "variables_get",
                                            fields: {
                                              VAR: {
                                                name: "x",
                                              },
                                            },
                                          },
                                        },
                                        TO: {
                                          block: {
                                            type: "variables_get",
                                            fields: {
                                              VAR: {
                                                name: "temp",
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                TO: {
                                  block: {
                                    type: "variables_get",
                                    fields: {
                                      VAR: {
                                        name: "temp",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },*/
  ],
};
export default Toolbox;
