import React, { useState, useEffect } from "react";
import "./App.css";
import Workspace from "./Workspace.js";
import Login from "./Login.js";
import Empty from "./Empty.js";
import SignUp from "./Signup";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserService from "./service.js";
import MarketPlace from "./MarketPlace";
import Settings from "./Settings";
import { themeChange } from "theme-change";

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const userService = new UserService();

  useEffect(() => {
    themeChange(false);
    userService.checkLogin().then((json) => {
      console.log(json);
      setUser(json);
      setLoading(false);
    });
  }, []);

  return (
    <div className="App flex flex-col">
      <Router>
        {loading ? (
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="*" element={<Empty />}></Route>
          </Routes>
        ) : (
          <>
            {user && "id" in user ? (
              <Routes>
                <Route path="/:id?" element={<Workspace user={user} />}></Route>
                <Route
                  path="/marketplace/:id?"
                  element={<MarketPlace user={user} />}
                ></Route>
                <Route
                  path="/settings"
                  element={<Settings user={user} />}
                ></Route>
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<Login />}></Route>

                <Route path="/signup" element={<SignUp />}></Route>
              </Routes>
            )}
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
