import Blockly, { FieldVariable } from "blockly";

export class GPIOFieldVariable extends FieldVariable {
  constructor(variable, validator, variableTypes, defaultType) {
    super(variable, validator, variableTypes, defaultType);
  }
  // renameVar(newName) {
  //   return;
  // }

  // deleteVar() {
  //   return;
  // }
  static dropdownCreate() {
    console.log("enter dropdown create");
    const menu = FieldVariable.dropdownCreate();
    for (let i = 0; i < menu.childElementCount; i++) {
      const option = menu.childNodes[i];
      if (option.value === "RENAME_VARIABLE") {
        menu.removeChild(option);
        break;
      }
    }
    return menu;
  }
  menuGenerator_() {
    const menu = super.menuGenerator_();
    for (let i = 0; i < menu.length; i++) {
      if (menu[i][0] === Blockly.Msg["RENAME_VARIABLE"]) {
        menu.splice(i, 1);
        i--;
      } else if (menu[i][0] === Blockly.Msg["DELETE_VARIABLE"]) {
        menu.splice(i, 1);
        i--;
      }
    }
    return menu;
  }

  // const { variable } = FieldVariable.fromJson(options);

  // return new GPIOFieldVariable(variable);
  // }
}
GPIOFieldVariable.fromJson = function (options) {
  const field = FieldVariable.fromJson(options);
  return new GPIOFieldVariable(field.variable_, null, ["GPIO"], "GPIO");
};
