var timerFlyoutCallback= function(workspace) {
  // Returns an array of hex colours, e.g. ['#4286f4', '#ef0447']
  var timerList = workspace.getVariablesOfType('Timer');
  console.log(timerList)
  var blockList = [
    {
      "kind": "button",
      "text": "Create a new Timer",
      "callbackkey":"create_new_timer"
    },
    {
      kind: "block",
      type: "time_date",
      colour: 20,
    },
    {
      'kind': 'block',
      'type': 'wait_ms',
    },
  ]

  if (timerList.length > 0) {
    var timer = timerList[0]

    blockList.push({
      'kind': 'block',
      'type': 'get_timer',
      'fields': {
        "VAR": timer
      }
    })
      
    blockList.push({
      'kind': 'block',
      'type': 'set_timer',
      'fields': {
        "VAR": timer
      }
    });

    blockList.push(
      {
      'kind': 'block',
      'type': 'get2_timer',
      'fields': {
        "VAR": timer
      }
    })
  }

  // for (var i = 0; i < timerList.length; i++) {
  //   var timer = timerList[i]
  //   console.log(timer)
  //   console.log(timer.name)
  //   blockList.push({
  //     'kind': 'block',
  //     'type': 'get_timer',
  //     'fields': {
  //       // "name": "TIMER",
  //       // "variabletype":timer.type,
  //       // "id": timer.getId(),
  //       "VAR":timer
  //     }
  //   })
  //     
  //   blockList.push({
  //     'kind': 'block',
  //     'type': 'set_timer',
  //     'fields': {
  //       // "name": "TIMER",
  //       // "variabletype":timer.type,
  //       // "id": timer.getId(),
  //       "VAR":timer
  //     }
  //   });
  // }

  blockList.push(
    {
    'kind': 'block',
    'type': 'start_timer',
  })
  blockList.push(
    {
    'kind': 'block',
    'type': 'stop_timer',
  })
  console.log('blocklist')
  console.log(blockList)
  return blockList;
};

export default timerFlyoutCallback;
