var GPIOFlyoutCallback = function (workspace) {
  var gpiolist = workspace.getVariablesOfType("GPIO");
  var blockList = [
    // we cannot create a new gpio
    // {
    //   kind: "button",
    //   text: "Create a new GPIO",
    //   callbackkey: "create_new_gpio",
    // },
  ];
  if (gpiolist.length > 0) {
    blockList.push({
      kind: "block",
      type: "set_gpio",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "set_gpio2",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "get_gpio",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "read_gpio",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "read_gpio2",
      fields: {
        VAR: gpiolist[0],
        state: "on"
      },
    });

    blockList.push({
      kind: "block",
      type: "wait_for_gpio",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "wait_for_gpio_block",
      fields: {
        VAR: gpiolist[0],
      },
    });

    blockList.push({
      kind: "block",
      type: "wait_for_gpio_state_change_block",
      fields: {
        VAR: gpiolist[0],
      },
    });
  }
  // for (var i = 0; i < gpiolist.length; i++) {
  //   var gpio = gpiolist[i];
  //   //push only one setter
  //   if (i === 0) {
  //     blockList.push({
  //       kind: "block",
  //       type: "set_gpio",
  //       fields: {
  //         VAR: gpio,
  //       },
  //     });
  //   }
  //   blockList.push({
  //     kind: "block",
  //     type: "get_gpio",
  //     fields: {
  //       VAR: gpio,
  //     },
  //   });
  // }
  return blockList;
};

export default GPIOFlyoutCallback;
