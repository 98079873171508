import React, { useState, useEffect } from "react";
import UserService from "./service.js";
import InviteByEmailModal from "./modals/invite_by_email.js";
import SaveAsNewProjectModal from "./modals/save_as_new_project.js";

const WorkspaceMenu = (props) => {
  const userService = new UserService();

  const [projects, setProj] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [name, setName] = useState("");
  const [shared, setShared] = useState(false);

  function logout() {
    userService.logout().then((json) => {
      console.log(json);
      window.location.reload(false);
    });
  }

  function getProjects() {
    userService.getAllProjects().then((json) => {
      setProj(json);
    });
  }

  function updateProject(proj, shared=false) {
    var form = new FormData();
    form.append("id", proj.id);
    form.append("name", name);
    form.append("shared", shared);

    userService.updateProject(form).then((json) => {
      console.log(json);
      window.location.reload();
      //setProj(json);
      //window.location.href = "/" + json;
    });
  }

  function deleteProj(id_) {
    var form = new FormData();
    form.append("id", id_);

    userService.deleteProject(form).then((resp) => {
      window.location.reload(false);
    });
  }

  function handleInviteModalOpen() {
    setShowInviteModal(true);
  }

  function handleInviteModalClose() {
    setShowInviteModal(false);
  }

  function handleSaveAsModalOpen() {
    setShowSaveAsModal(true);
  }

  function handleSaveAsModalClose() {
    setShowSaveAsModal(false);
  }

  function handleSharedChange(e) {
    setShared(e.target.checked);
    updateProject(props.proj, e.target.checked);
  }

  useEffect(() => {
    console.log(props.proj);
    if (props.proj) {
      setShared(props.proj.shared);
      setName(props.proj.name);
    }
  }, props.proj);

  return (
    <>
      <div className="flex flex-horizontal items-center justify-between py-1">
        <InviteByEmailModal
          showModal={showInviteModal}
          handleModalClose={handleInviteModalClose}
        ></InviteByEmailModal>
        <SaveAsNewProjectModal
          showModal={showSaveAsModal}
          handleModalClose={handleSaveAsModalClose}
          handleSaveAsNewProject={props.handleSaveAsNewProject}
        ></SaveAsNewProjectModal>
        <input type="checkbox" id="open-proj-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative">
            <label
              htmlFor="open-proj-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>

            <h3 className="font-bold text-lg mb-2">List of your projects</h3>

            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((proj) =>
                    proj.id === "" ? (
                      <tr>
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-star"
                          >
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                          </svg>
                        </th>
                        <td>{proj.name}</td>
                        <td className="flex flex-horizontal items-center">
                          <button
                            onClick={() =>
                              (window.location.href =
                                "/default_" + proj.name.replaceAll(" ", "_"))
                            }
                            className="btn btn-sm mx-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                              <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr key={proj.id}>
                        <th>{proj.id}</th>
                        <td>{proj.name}</td>
                        <td className="flex flex-horizontal items-center">
                          <button
                            onClick={() =>
                              (window.location.href = "/" + proj.id.toString())
                            }
                            className="btn btn-sm mx-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                              <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                          </button>
                          <label
                            for={"delete-proj-modal" + proj.id}
                            className="btn btn-sm mx-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </label>
                          {/* Delete Project Confirmation modal */}
                          <input
                            type="checkbox"
                            id={"delete-proj-modal" + proj.id}
                            className="modal-toggle"
                          />
                          <div className="modal">
                            <div className="modal-box">
                              <label
                                for={"delete-proj-modal" + proj.id}
                                className="btn btn-sm btn-circle absolute right-2 top-2"
                              >
                                ✕
                              </label>
                              <h3 className="font-bold text-lg">
                                Confirm Deletion
                              </h3>

                              <label className="label">
                                <span className="label-text">
                                  Are you sure you want to delete {proj.name}?
                                </span>
                              </label>
                              <div className="modal-action">
                                <label
                                  onClick={() => deleteProj(proj.id)}
                                  for="create-proj-modal"
                                  className="btn"
                                >
                                  Confirm
                                </label>
                              </div>
                            </div>
                          </div>

                          <label
                            for={"update-proj-modal" + proj.id}
                            className="btn btn-sm mx-1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit-2"
                            >
                              <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                            </svg>
                          </label>
                          {/* Rename Project Confirmation modal */}
                          <input
                            type="checkbox"
                            id={"update-proj-modal" + proj.id}
                            className="modal-toggle"
                          />
                          <div className="modal">
                            <div className="modal-box">
                              <label
                                for={"update-proj-modal" + proj.id}
                                className="btn btn-sm btn-circle absolute right-2 top-2"
                              >
                                ✕
                              </label>
                              <h3 className="font-bold text-lg">
                                Confirm Update
                              </h3>
                              <div className="form-control">
                                <label className="label">
                                  <span className="label-text">Name</span>
                                </label>
                                <label className="input-group">
                                  <span>*</span>
                                  <input
                                    defaultValue={proj.name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    placeholder="Name"
                                    className="input input-bordered"
                                  />
                                </label>
                              </div>
                              <div className="modal-action">
                                <label
                                  onClick={() => updateProject(proj)}
                                  for="create-proj-modal"
                                  className="btn"
                                >
                                  Confirm
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ul className="menu menu-horizontal bg-base-100">
          {/*<li><a>File</a></li>*/}
          <li tabIndex="0">
            <span>File</span>
            <ul className="menu bg-base-100" style={{ zIndex: 10 }}>
              <li>
                <label onClick={() => getProjects()} htmlFor="open-proj-modal">
                  Open Project
                </label>
              </li>
              <li>
                <button onClick={() => (window.location.href = "/")}>
                  New Project
                </button>
              </li>
              <li tabIndex={0}>
                <span>Save As </span>

                <ul className=" bg-base-100" style={{ zIndex: 10 }}>
                  <li>
                    <button onClick={() => props.handleDownload()}>
                      Download
                    </button>
                  </li>
                  <li>
                    <button onClick={() => handleSaveAsModalOpen()}>
                      Save As New Project
                    </button>
                  </li>
                </ul>
              </li>
              <li>
                <button onClick={() => (window.location.href = "/marketplace")}>
                  MarketPlace
                </button>
              </li>
              {props.user.email == "danielratke97@hotmail.de" ||
              props.user.email == "sean@automatika.us" ? (
                <>
                  <li>
                    <button onClick={handleInviteModalOpen}>
                      Invite a Friend
                    </button>
                  </li>
                </>
              ) : (
                ""
              )}
              <li>
                <button onClick={() => (window.location.href = "/settings")}>
                  Settings
                </button>
              </li>
              <li>
                <button onClick={() => logout()}>Logout</button>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">Help</a>
          </li>
        </ul>

        {(props.proj && "project_str" in props.proj) ? (
          <div className="flex flex-horizontal items-center px-5">
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text">Shared:&nbsp;</span>
                <input type="checkbox" className="toggle" onChange={(e) => handleSharedChange(e)} checked={shared} />
              </label>
            </div>


            <div className="px-5">
              <div className="badge badge-primary pointer-events-none">
                {props.proj ? props.proj.name : ""}
              </div>
            </div>

            <button
              className="btn btn-circle btn-success btn-outline btn-sm mr-2"
              onClick={() => props.handleCodeGen()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-hash"
              >
                <line x1="4" y1="9" x2="20" y2="9"></line>
                <line x1="4" y1="15" x2="20" y2="15"></line>
                <line x1="10" y1="3" x2="8" y2="21"></line>
                <line x1="16" y1="3" x2="14" y2="21"></line>
              </svg>
            </button>

            <button
              className="btn btn-circle btn-success btn-outline btn-sm mr-2"
              onClick={() => props.handleRun()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-play"
              >
                <polygon points="5 3 19 12 5 21 5 3"></polygon>
              </svg>
            </button>

            <button
              className="btn btn-circle btn-error btn-outline btn-sm"
              onClick={() => props.handleCancel()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-square"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              </svg>
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default WorkspaceMenu;
