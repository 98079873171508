import React, { useState, useEffect } from "react";
import validator from "validator";
import { IP } from "./config.js";
import UserService from "./service.js";

const SignUp = (props) => {
  const userService = new UserService();
  const defaultObject = {
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
  };

  const [inputs, setInputs] = useState(defaultObject);
  const [validationError, setValidationError] = useState(defaultObject);
  const [isValid, setIsValid] = useState(defaultObject);
  const [isDirty, setIsDirty] = useState(defaultObject);
  const [error, setError] = useState("");

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "name":
        validateName(value);
        break;

      case "email":
        validateEmail(value);
        break;

      case "password":
        validatePassword(value);
        break;

      default: //confirnm Password
        validateConfirmPassword(value);
        break;
    }
    setIsDirty((values) => ({ ...values, [name]: true }));
    setInputs((values) => ({ ...values, [name]: value }));
  }

  const required = (value) => {
    return !validator.isEmpty(value);
  };
  const validEmail = (value) => {
    return validator.isEmail(value);
  };
  const samePasswords = (value) => {
    return validator.equals(inputs.password, value);
  };

  function validateName(name) {
    const validity = required(name);
    setIsValid((values) => ({
      ...values,
      name: validity,
    }));
    setValidationError((values) => ({
      ...values,
      name: validity ? "" : "Name is required.",
    }));
  }

  function validateEmail(email) {
    const validity = [required(email), validEmail(email)];

    setIsValid((values) => ({
      ...values,
      email: validity[0] && validity[1],
    }));
    setValidationError((values) => ({
      ...values,
      email: validity[0]
        ? validity[1]
          ? ""
          : "Email is not valid."
        : "Email is required.",
    }));
  }

  function validatePassword(password) {
    const validity = required(password);
    setIsValid((values) => ({
      ...values,
      password: validity,
    }));
    setValidationError((values) => ({
      ...values,
      password: validity ? "" : "Password is required.",
    }));
  }

  function validateConfirmPassword(confirmPassword) {
    const validity = [
      required(confirmPassword),
      samePasswords(confirmPassword),
    ];

    setIsValid((values) => ({
      ...values,
      confirmPassword: validity[0] && validity[1],
    }));
    setValidationError((values) => ({
      ...values,
      confirmPassword: validity[0]
        ? validity[1]
          ? ""
          : "Passwords are not same."
        : "Enter the password to confirm.",
    }));
  }

  const isSaveButtonDisabled = () => {
    let disabled = true;
    isValid.forEach((key, value) => {
      value ? (disabled = false) : (disabled = true);
    });
    return disabled;
  };

  function handleSubmit(e) {
    e.preventDefault();

    const formData = e.target;
    const form = new FormData(formData);
    userService
      .registerUser(form)
      .then((resp) => {
        resp.json().then((json) => {
          console.log(json);
          if (!("error" in json)) {
            window.location.href = "/";
          } else {
            setError(json["error"]);
          }
        });
      })
      .catch(function () {
        setError("no internet connection");
      });
  }

  useEffect(() => {});

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="card w-1/3 card-normal bg-base-100 shadow-xl my-10">
          <figure className="px-10 pt-10">
            <img
              className="mask mask-hexagon-2"
              src={IP + "/static/lokodio_logo.png"}
              alt="LoKoDIO Logo"
              width="100"
            />
          </figure>
          <form method="post" onSubmit={handleSubmit}>
            <div className="card-body items-center text-center">
              <h2 className="card-title">SignUp to Automatika</h2>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  onChange={handleChange}
                  name="name"
                  type="text"
                  placeholder="Full Name"
                  value={inputs.name}
                  className={
                    "input input-bordered" +
                    (isDirty.name ? (isValid.name ? "" : " input-error") : "")
                  }
                />
                <small className="text-error">{validationError.name}</small>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  type="text"
                  placeholder="Email"
                  value={inputs.email}
                  className={
                    "input input-bordered" +
                    (isDirty.email ? (isValid.email ? "" : " input-error") : "")
                  }
                />
                <small className="text-error">{validationError.email}</small>
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  onChange={handleChange}
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={inputs.password}
                  className={
                    "input input-bordered" +
                    (isDirty.password
                      ? isValid.password
                        ? ""
                        : " input-error"
                      : "")
                  }
                />
                <small className="text-error">{validationError.password}</small>
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Confirm Password</span>
                </label>
                <input
                  onChange={handleChange}
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Your Password"
                  value={inputs.confirmPassword}
                  className={
                    "input input-bordered" +
                    (isDirty.confirmPassword
                      ? isValid.confirmPassword
                        ? ""
                        : " input-error"
                      : "")
                  }
                />
                <small className="text-error">
                  {validationError.confirmPassword}
                </small>
              </div>
            </div>
            <div className="card-actions flex justify-center ">
              <input
                type="submit"
                className="btn btn-primary my-10"
                disabled={isSaveButtonDisabled}
                value="Let's Go!"
              />
            </div>
          </form>
        </div>
      </div>
      {error ? (
        <div className="toast">
          <div className="alert alert-error">
            <div>
              <span>Error: {error}</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default SignUp;
