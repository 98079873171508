import Blockly from "blockly";
import "./custom_mutators.js";
import "./custom_generators.js";
import {createPlusField} from './plus-minus/field_plus';
import {createMinusField} from './plus-minus/field_minus';
import { GPIOFieldVariable } from "./GPIO_Field_var.js";

Blockly.fieldRegistry.register("gpio_field_variable", GPIOFieldVariable);

Blockly.defineBlocksWithJsonArray([
  // IF THEN ELSEIF ELSE
  // {
  //   type: "if_then_elseif_else",
  //   message0: "if %1",
  //   args0: [
  //     {
  //       type: "input_value",
  //       name: "IF0",
  //       check: "Boolean",
  //     },
  //   ],
  //   message1: "then %1",
  //   args1: [
  //     {
  //       type: "input_statement",
  //       name: "DO0",
  //     },
  //   ],
  //   message2: "else if %1",
  //   args2: [
  //     {
  //       type: "input_statement",
  //       name: "IF1",
  //     },
  //   ],
  //   message3: "then %1",
  //   args3: [
  //     {
  //       type: "input_statement",
  //       name: "DO1",
  //     },
  //   ],
  //   message4: "else %1",
  //   args4: [
  //     {
  //       type: "input_statement",
  //       name: "ELSE",
  //     },
  //   ],
  //   previousStatement: null,
  //   nextStatement: null,
  //   style: "logic_blocks",
  //   suppressPrefixSuffix: true,
  //   mutator: "custom_controls_if_mutator",
  //   extensions: ["controls_if_tooltip"],
  // },
  // VARIABLE COMPARATOR
  {
    type: "variable_comparator",
    message0: "%1 %2 %3 %4 %5",
    args0: [
      {
        type: "field_variable",
        name: "VARIABLE",
        variable: "variable1",
      },
      {
        type: "input_dummy",
      },
      {
        type: "field_dropdown",
        name: "OPERATOR",
        options: [
          ["=", "equal_to"],
          ["≠", "not_equal_to"],
          [">", "greater_than"],
          ["≥", "greater_or_equal"],
          ["<", "less_than"],
          ["≤", "less_or_equal"],
        ],
      },
      {
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "VALUE",
      },
    ],
    inputsInline: true,
    output: null,
    colour: 210,
    tooltip:
      "Compare two values of any type provided they are similar. While the first one is pre populated with a variable.",
    helpUrl: "",
  },
  // LOOP NUMBER
  {
    type: "loop_number",
    message0: "repeat %1 times %2 do %3",
    args0: [
      {
        type: "field_number",
        name: "NUMBER",
        value: 123,
      },
      {
        type: "input_dummy",
      },
      {
        type: "input_statement",
        name: "STATEMENT",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 120,
    tooltip: "",
    helpUrl: "",
  },
  // LOOP VARIABLE
  {
    type: "loop_variable",
    message0: "repeat %1 times %2 do %3",
    args0: [
      {
        type: "field_variable",
        name: "NAME",
        variable: "variable",
      },
      {
        type: "input_dummy",
      },
      {
        type: "input_statement",
        name: "STATEMENT",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 120,
    tooltip: "Loop for a number of times.  Prepopulated with variable field",
    helpUrl: "",
  },
  // LOOP FOREVER
  {
    type: "loop_forever",
    message0: "repeat forever %1 do %2",
    args0: [
      {
        type: "input_dummy",
      },
      {
        type: "input_statement",
        name: "STATEMENT",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 120,
    tooltip:
      "Loops forever.  Must have a break out condition (or it’ll crash obviously).  Might be doable with Loop While IO above.",
    helpUrl: "",
  },

  // CONSTRAIN VALUE
  {
    type: "constrain",
    message0: "constrain %1 low %2 high %3",
    args0: [
      {
        type: "field_variable",
        name: "VARIABLE",
        variable: "variable",
      },
      {
        type: "field_number",
        name: "LOW",
        value: 1,
      },
      {
        type: "field_number",
        name: "HIGH",
        value: 0,
      },
    ],
    output: null,
    colour: 230,
    tooltip: "Constrains a variable between two values (can be variables)",
    helpUrl: "",
  },
  // TIME OD DAY/ DATE
  {
    type: "time_date",
    message0: "%1",
    args0: [
      {
        type: "field_dropdown",
        name: "TIME_OR_DATE",
        options: [
          ["Time of Day", "TIME"],
          ["Date", "DATE"],
        ],
      },
    ],
    output: null,
    colour: 230,
    tooltip: "",
    helpUrl: "",
  },
  // SPACER
  {
    type: "spacer",
    message0: "%1",
    args0: [
      {
        type: "field_label_serializable",
        name: "NAME",
        text: "                             ",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 160,
    tooltip: "Adds new line to code",
    helpUrl: "",
  },
  // COMMENT
  {
    type: "comment",
    message0: "comment %1",
    args0: [
      {
        type: "field_input",
        name: "COMMENT",
        text: "This is a comment",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 160,
    tooltip: "Adds comments for code description",
    helpUrl: "",
  },
  // LOG
  {
    type: "log",
    message0: "log %1",
    args0: [
      {
        type: "input_value",
        name: "log_text",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 160,
    tooltip: "",
    helpUrl: "",
  },
  // WRITE TO SD
  {
    type: "write_to_sd",
    message0: "Append to SD %1 File %2 Data %3 New Line %4 Chip Select %5",
    args0: [
      {
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "file_name",
        check: "String",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "data",
        check: "String",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "is_new_line",
        check: "Boolean",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "chip_select",
        align: "RIGHT",
      },
    ],
    inputsInline: false,
    previousStatement: null,
    nextStatement: null,
    colour: 160,
    tooltip: "",
    helpUrl: "",
  },
  // WAIT IN MS
  {
    type: "wait_ms",
    message0: "Wait in ms %1",
    args0: [
      {
        type: "input_value",
        name: "milliseconds",
        check: "Number",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 20,
    tooltip: "Wait for number or variable of ms.  ",
    helpUrl: "",
  },
  // GPIO
  {
    type: "read_gpio",
    message0: "Read %1",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"], // Specifies what types to put in the dropdown
        defaultType: "GPIO",
      },
    ],
    output: "Boolean",
    colour: 20,
    tooltip: "Read the GPIO",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  {
    type: "read_gpio2",
    message0: "GPIO %1 is %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"], // Specifies what types to put in the dropdown
        defaultType: "GPIO",
      },
      {
        "type": "field_dropdown",
        "name": "state",
        "options": [
          [ "on", "ON" ],
          [ "off", "OFF" ]
        ]
      },
    ],
    output: "Boolean",
    colour: 20,
    tooltip: "Read the GPIO",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  {
    type: "get_gpio",
    message0: "%1",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
        variableTypes: ["GPIO"], // Specifies what types to put in the dropdown
        defaultType: "GPIO",
      },
    ],
    //output: "GPIO",
    output: "Boolean",
    colour: 20,
    tooltip: "Get the GPIO",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  // {
  //   type: "set_gpio",
  //   message0: "GPIO Name %1 %2   Pin number %3 %4 ON/OFF  %5",
  //   args0: [
  //     {
  //       type: "field_variable",
  //       name: "VAR",
  //       variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
  //       variableTypes: ["GPIO"],
  //       defaultType: "GPIO",
  //     },
  //     // {
  //     //   type: "input_value",
  //     //   name: "VALUE",
  //     //   check: "Boolean",
  //     // },
  //     {
  //       type: "input_dummy",
  //     },
  //     {
  //       type: "field_input",
  //       name: "pin_num",
  //       text: "default",
  //     },
  //     {
  //       type: "input_dummy",
  //     },
  //     {
  //       type: "field_checkbox",
  //       name: "on_off",
  //       checked: true,
  //     },
  //   ],
  //   previousStatement: null,
  //   nextStatement: null,
  //   colour: 20,
  //   tooltip: "Set the value for the GPIO ",
  //   extensions: ["contextMenu_variableSetterGetter"],
  // },

  {
    type: "set_gpio",
    message0: "Set GPIO %1 %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
        variableTypes: ["GPIO"],
        defaultType: "GPIO",
      },
      {
        type: "input_value",
        name: "VALUE",
        check: "Boolean",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 20,
    tooltip: "Set the value for the GPIO ",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  {
    type: "set_gpio2",
    message0: "Set GPIO %1 to %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
        variableTypes: ["GPIO"],
        defaultType: "GPIO",
      },
      {
        "type": "field_dropdown",
        "name": "state",
        "options": [
          [ "on", "ON" ],
          [ "off", "OFF" ]
        ]
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 20,
    tooltip: "Set the value for the GPIO ",
    extensions: ["contextMenu_variableSetterGetter"],
  },

  // Block for Timer variable getter.
  {
    type: "get_timer",
    message0: "%1",
    args0: [
      {
        type: "field_variable",
        name: "VAR",
        variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
        variableTypes: ["Timer"], // Specifies what types to put in the dropdown
        defaultType: "Timer",
      },
    ],
    output: "Timer", // Returns a value of "Timer"
    colour: 20,
    tooltip: "Get the timer",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  {
    type: "get2_timer",
    message0: "Read %1",
    args0: [
      {
        type: "field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["Timer"],
        defaultType: "Timer",
      },
    ],
    output: "Number",
    colour: 20,
    tooltip: "Get value of timer",
    extensions: ["contextMenu_variableSetterGetter"],
  },
  {
    type: "set_timer",
    message0: "Set %1 Count %2 %3",
    args0: [
      {
        type: "field_variable",
        name: "VAR",
        variable: "%{BKY_VARIABLES_DEFAULT_NAME}",
        variableTypes: ["Timer"],
        defaultType: "Timer",
      },
      {
        type: "field_dropdown",
        name: "TIMER_TYPE",
        options: [
          ["Down", "CD"],
          ["Up", "CU"],
        ],
      },
      {
        type: "input_value",
        name: "VALUE",
        check: "Number",
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 20,
    tooltip: "Set the value for the timer in milliseconds ",
    extensions: ["contextMenu_variableSetterGetter"],
  },

  {
    type: "start_timer",
    message0: "Start Timer %1",
    args0: [
      {
        type: "input_value",
        name: "TIMER",
        check: "Timer",
      },
    ],
    colour: 20,
    previousStatement: null,
    nextStatement: null,
  },

  {
    type: "stop_timer",
    message0: "Stop Timer %1",
    args0: [
      {
        type: "input_value",
        name: "TIMER",
        check: "Timer",
      },
    ],
    colour: 20,
    previousStatement: null,
    nextStatement: null,
  },

  {
    type: "read_gpio2",
    message0: "GPIO %1 is %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"], // Specifies what types to put in the dropdown
        defaultType: "GPIO",
      },
      {
        "type": "field_dropdown",
        "name": "state",
        "options": [
          [ "on", "ON" ],
          [ "off", "OFF" ]
        ]
      },
    ],
    output: "Boolean",
    colour: 20,
    tooltip: "Read the GPIO",
    extensions: ["contextMenu_variableSetterGetter"],
  },

  {
    type: "wait_for_gpio",
    message0: "Wait until GPIO %1 is %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"],
        defaultType: "GPIO",
      },
      {
        "type": "field_dropdown",
        "name": "state",
        "options": [
          [ "on", "ON" ],
          [ "off", "OFF" ]
        ]
      },
    ],
    "message1": "%{BKY_CONTROLS_REPEAT_INPUT_DO} %1",
    "args1": [{
      "type": "input_statement",
      "name": "DO"
    }],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 20,
    "tooltip": "Wait until the GPIO has the given state, and execute the statements then.",
  },

  {
    type: "wait_for_gpio_block",
    message0: "Blocking wait until GPIO %1 is %2",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"],
        defaultType: "GPIO",
      },
      {
        "type": "field_dropdown",
        "name": "state",
        "options": [
          [ "on", "ON" ],
          [ "off", "OFF" ]
        ]
      },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 20,
    "tooltip": "Blocking wait until the GPIO has the given state, and execute the statements then.",
  },

  {
    type: "wait_for_gpio_state_change_block",
    message0: "Blocking wait until GPIO %1 has changed state.",
    args0: [
      {
        type: "gpio_field_variable",
        name: "VAR",
        variable: "",
        variableTypes: ["GPIO"],
        defaultType: "GPIO",
      }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 20,
    "tooltip": "Blocking wait until the GPIO has changed its state.",
  },

  {
    type: "wait_for_var",
    message0: "Wait until variable %1 is %2",
    args0: [
      {
        type: "field_variable",
        name: "VAR",
        variable: "",
      },
      {
        type: "input_value",
        name: "state",
      },
    ],
    "message1": "%{BKY_CONTROLS_REPEAT_INPUT_DO} %1",
    "args1": [{
      "type": "input_statement",
      "name": "DO"
    }],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 330,
    "tooltip": "Wait until the variable has the given state, and execute the statements then.",
  },

  {
    type: "wait_for_var_block",
    message0: "Blocking wait until variable %1 is %2",
    args0: [
      {
        type: "field_variable",
        name: "VAR",
        variable: "",
      },
      {
        type: "input_value",
        name: "state",
      },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 330,
    "tooltip": "Blocking wait until the variable has the given state, and execute the statements then.",
  },

  {
    'type': 'analog_map',
    'message0': 'Map %1 from %2 - %3 to %4 - %5 ',
    'args0': [
      {
        'type': 'field_variable',
        'name': 'VAR',
        'variable': null,
      },
      {
        'type': 'field_number',
        'name': 'FROM1',
        'value': 1,
        'precision': 1,
      },
      {
        'type': 'field_number',
        'name': 'FROM2',
        'value': 1024,
        'precision': 1,
      },
      {
        'type': 'field_number',
        'name': 'TO1',
        'value': 1,
        'precision': 1,
      },
      {
        'type': 'field_number',
        'name': 'TO2',
        'value': 32,
        'precision': 1,
      },
    ],
    output: "Boolean",
    'inputsInline': true,
    'style': 'loop_blocks',
  },

  {
    'type': 'controls_until_multiprocessing',
    'message0': 'Repeat until %1, break immediately',
    'args0': [
      {
        'type': 'input_value',
        'name': 'BOOL',
        'check': 'Boolean',
      },
    ],
    'message1': 'Do %1',
    'args1': [{
      'type': 'input_statement',
      'name': 'DO',
    }],
    'previousStatement': null,
    'nextStatement': null,
    'style': 'loop_blocks',
    'helpUrl': 'Repeat until condition is met',
  },

]);

var CustomToolbox = {
  kind: "categoryToolbox",
  autoClose: false,
  contents: [
    {
      kind: "category",
      name: "Logic",
      categorystyle: "logic_category",
      contents: [
        {
          kind: "block",
          type: "if_then_elseif_else",
        },
        {
          kind: "block",
          type: "variable_comparator",
        },
      ],
    },
  ],
};

Blockly.Blocks['controls_logical_and'] = {
  init: function() {
    this.appendDummyInput().appendField('AND');
    this.appendValueInput('AND1').setCheck('Boolean');
    this.appendValueInput('AND2').setCheck('Boolean');
    this.setInputsInline(false);
    this.setOutput(true, "Boolean");
    this.setColour(30);
    this.setTooltip('Logical AND');
    this.setMutator(new Blockly.Mutator(['controls_condition_block']));
    this.numberOfChildren = 2;
  },
  mutationToDom: function()
  {
      if(!this.numberOfChildren)
      {
          return null;
      }
      var container = document.createElement('mutation');
      if(this.numberOfChildren)
      {
          container.setAttribute('children', this.numberOfChildren);
      }
      return container;
  },
  domToMutation: function(xmlElement)
  {
      this.numberOfChildren = parseInt(xmlElement.getAttribute('children'), 10) || 0;
      for(var i = 3; i <= this.numberOfChildren; i++)
      {
          this.appendValueInput('AND' + i)
              .setCheck('Boolean');
      }
  },
  decompose: function(workspace)
  {
      var containerBlock = Blockly.Block.obtain(workspace, 'controls_and_tooltip');
      containerBlock.initSvg();
      var connection = containerBlock.getInput('STACK').connection;
      for(var i = 1; i <= this.numberOfChildren; i++)
      {
          var conditionBlock = Blockly.Block.obtain(workspace, 'controls_condition_block');
          conditionBlock.initSvg();
          connection.connect(conditionBlock.previousConnection);
          connection = conditionBlock.nextConnection;
      }
      return containerBlock;
  },
  compose: function(containerBlock)
  {
      for(var i = this.numberOfChildren; i > 0; i--)
      {
          this.removeInput('AND' + i);
      }
      this.numberOfChildren = 0;
      var clauseBlock = containerBlock.getInputTargetBlock('STACK');
      while(clauseBlock)
      {
          this.numberOfChildren++;
          var andInput = this.appendValueInput('AND' + this.numberOfChildren)
                             .setCheck('Boolean');
                             //.appendField('condition' + this.numberOfChildren);
          if(clauseBlock.valueConnection_)
          {
              andInput.connection.connect(clauseBlock.valueConnection_);
          }
          clauseBlock = clauseBlock.nextConnection &&
          clauseBlock.nextConnection.targetBlock();
      }
  },
  saveConnections: function(containerBlock)
  {
      var clauseBlock = containerBlock.getInputTargetBlock('STACK');
      var i = 1;
      while(clauseBlock)
      {
          var andInput = this.getInput('AND' + i);
          clauseBlock.valueConnection_ = andInput && andInput.connection.targetConnection;
          i++;
          clauseBlock = clauseBlock.nextConnection &&
          clauseBlock.nextConnection.targetBlock();
      }
  }
}

Blockly.Blocks['controls_and_tooltip'] =
{
    init: function() {
    this.setColour(210);
    this.appendDummyInput()
        .appendField('AND');
    this.appendStatementInput('STACK');
    this.setTooltip('multiple and control');
    this.contextMenu = false;
    }
}

Blockly.Blocks['controls_condition_block'] =
{
    init: function() {
    this.setColour(210);
    this.appendDummyInput()
        .appendField('condition');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setTooltip('conditionalStatement');
    this.contextMenu = false;
    }
};

// LOGICAL AND

Blockly.defineBlocksWithJsonArray([
  {
    "type": "logical_and_plus_minus",
    "message0": "AND %1",
    "args0": [
      {
        "type": "input_dummy",
        "name": "EMPTY",
      },
    ],
    "output": "Boolean",
    "colour": 210,
    "helpUrl": "Logical AND",
    "tooltip": "Logical AND",
    "mutator": "logical_and_mutator",
  },
]);

Blockly.defineBlocksWithJsonArray([
  {
    "type": "logical_or_plus_minus",
    "message0": "OR %1",
    "args0": [
      {
        "type": "input_dummy",
        "name": "EMPTY",
      },
    ],
    "output": "Boolean",
    "colour": 210,
    "helpUrl": "Logical OR",
    "tooltip": "Logical OR",
    "mutator": "logical_or_mutator",
  },
]);

const logicalMutator = (type_) => { return {
  itemCount_: 0,

  mutationToDom: function() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation: function(xmlElement) {
    const targetCount = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(targetCount);
  },

  saveExtraState: function() {
    return {
      'itemCount': this.itemCount_,
    };
  },

  loadExtraState: function(state) {
    this.updateShape_(state['itemCount']);
  },

  updateShape_: function(targetCount) {
    while (this.itemCount_ < targetCount) {
      this.addPart_();
    }
    while (this.itemCount_ > targetCount) {
      this.removePart_();
    }
    this.updateMinus_();
  },

  plus: function() {
    this.addPart_();
    this.updateMinus_();
  },

  minus: function() {
    if (this.itemCount_ == 0) {
      return;
    }
    this.removePart_();
    this.updateMinus_();
  },

  addPart_: function() {
    if (this.itemCount_ == 0) {
      this.removeInput('EMPTY');
      this.topInput_ = this.appendValueInput('ADD' + this.itemCount_)
          .appendField(createPlusField(), 'PLUS')
          .appendField('Logical ' + type_);
    } else {
      this.appendValueInput('ADD' + this.itemCount_);
    }
    this.itemCount_++;
  },

  removePart_: function() {
    this.itemCount_--;
    this.removeInput('ADD' + this.itemCount_);
    if (this.itemCount_ == 0) {
      this.topInput_ = this.appendDummyInput('EMPTY')
          .appendField(createPlusField(), 'PLUS')
          .appendField('Logical ' + type_);
    }
  },

  updateMinus_: function() {
    const minusField = this.getField('MINUS');
    if (!minusField && this.itemCount_ > 0) {
      this.topInput_.insertFieldAt(1, createMinusField(), 'MINUS');
    } else if (minusField && this.itemCount_ < 1) {
      this.topInput_.removeField('MINUS');
    }
  },
}};

const logicalHelper = function() {
  this.getInput('EMPTY').insertFieldAt(0, createPlusField(), 'PLUS');
  this.updateShape_(2);
};

Blockly.Extensions.registerMutator('logical_and_mutator',
    logicalMutator('AND'), logicalHelper);

Blockly.Extensions.registerMutator('logical_or_mutator',
    logicalMutator('OR'), logicalHelper);

export default CustomToolbox;
