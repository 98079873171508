import Blockly from "blockly";
import { pythonGenerator } from "blockly/python";
import "./custom_blocks.js";

// IF THEN ELIF THEN ELSE
pythonGenerator["if_then_elseif_else"] = pythonGenerator["controls_if"];

// VARIABLE COMPARATOR
pythonGenerator["variable_comparator"] = function (block) {
  var variable = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VARIABLE"),
    Blockly.Names.NameType.VARIABLE
  );
  var dropdown_operator = block.getFieldValue("OPERATOR");
  var value = pythonGenerator.valueToCode(
    block,
    "VALUE",
    pythonGenerator.ORDER_ATOMIC
  );
  var code = "";
  switch (dropdown_operator) {
    case "equal_to":
      code = "" + variable + " == " + value + "\n";
      break;
    case "not_equal_to":
      code = "" + variable + " != " + value + "\n";
      break;
    case "greater_than":
      code = "" + variable + " > " + value + "\n";
      break;
    case "greater_or_equal":
      code = "" + variable + " >= " + value + "\n";
      break;
    case "less_than":
      code = "" + variable + " == " + value + "\n";
      break;
    case "less_or_equal":
      code = "" + variable + " <= " + value + "\n";
      break;
    default:
      code = "" + variable + "==" + value + "\n";
  }
  return [code, pythonGenerator.ORDER_NONE];
};

// LOOP NUMBER
pythonGenerator["loop_number"] = function (block) {
  var number = block.getFieldValue("NUMBER");
  var statement = pythonGenerator.statementToCode(block, "STATEMENT");

  var code = "for x in range(" + number + "):\n" + statement + "\n";
  return code;
};

// LOOP VARIABLE
pythonGenerator["loop_variable"] = function (block) {
  var variable = pythonGenerator.nameDB_.getName(
    block.getFieldValue("NAME"),
    Blockly.Names.NameType.VARIABLE
  );
  var statement = pythonGenerator.statementToCode(block, "STATEMENT");

  var code = "for x in range(" + variable + "):\n" + statement + "\n";
  return code;
};

// LOOP FOREVER
pythonGenerator["loop_forever"] = function (block) {
  var statement = pythonGenerator.statementToCode(block, "STATEMENT");

  var code = "while True:\n" + statement + "\n";
  return code;
};

// CONSTRAIN VALUE
pythonGenerator["constrain"] = function (block) {
  var variable = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VARIABLE"),
    Blockly.Names.NameType.VARIABLE
  );
  var number_low = block.getFieldValue("LOW");
  var number_high = block.getFieldValue("HIGH");

  var code =
    "max(min(" + number_high + "," + variable + ")," + number_low + ")\n";

  return [code, pythonGenerator.ORDER_NONE];
};

// TIME OF DAY/ DATE
pythonGenerator["time_date"] = function (block) {
  var dropdown_time_or_date = block.getFieldValue("TIME_OR_DATE");
  pythonGenerator.definitions_["from_datetime_import_datetime"] =
    "from datetime import datetime";
  var code = "";
  if (dropdown_time_or_date === "TIME") {
    code = 'datetime.now().strftime("%H:%M:%S")';
  } else {
    code = 'datetime.today().strftime("%m/%d/%Y")';
  }

  return [code, pythonGenerator.ORDER_NONE];
};

// SPACER
pythonGenerator["spacer"] = function (block) {
  var code = "\n";
  return code;
};

// COMMENT
pythonGenerator["comment"] = function (block) {
  var text_comment = block.getFieldValue("COMMENT");

  var code = "//" + text_comment + "\n";
  return code;
};

// LOG
pythonGenerator["log"] = function (block) {
  var log_text = pythonGenerator.valueToCode(
    block,
    "log_text",
    pythonGenerator.ORDER_ATOMIC
  );
  pythonGenerator.definitions_["import_logging"] = "import logging";
  // TODO: change file name according to requirement. Can be made into a value input
  var code =
    "logging.basicConfig(filename='example.log', level=logging.INFO, format='%(asctime)s %(message)s', datefmt='%Y-%m-%d %H:%M:%S')\n" +
    " logging.info('" +
    log_text +
    "')\n";
  return code;
};

// WRITE TO SD
pythonGenerator["write_to_sd"] = function (block) {
  var file_name = pythonGenerator.valueToCode(
    block,
    "file_name",
    pythonGenerator.ORDER_ATOMIC
  );
  var data = pythonGenerator.valueToCode(
    block,
    "data",
    pythonGenerator.ORDER_ATOMIC
  );
  var is_new_line = pythonGenerator.valueToCode(
    block,
    "is_new_line",
    pythonGenerator.ORDER_ATOMIC
  );
  var chip_select = pythonGenerator.valueToCode(
    block,
    "chip_select",
    pythonGenerator.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code = "...\n";
  return code;
};

// WAIT IN MS
pythonGenerator["wait_ms"] = function (block) {
  var value_milliseconds = pythonGenerator.valueToCode(
    block,
    "milliseconds",
    pythonGenerator.ORDER_ATOMIC
  );
  pythonGenerator.definitions_["import_time"] = "import time";
  var code = "time.sleep(" + value_milliseconds + "/1000)\n";
  return code;
};

pythonGenerator["start_timer"] = function (block) {
  var timer = pythonGenerator.valueToCode(
    block,
    "TIMER",
    pythonGenerator.ORDER_ATOMIC
  );
  var code = timer + ".startTimer()\n";

  return code;
};

pythonGenerator["stop_timer"] = function (block) {
  var timer = pythonGenerator.valueToCode(
    block,
    "TIMER",
    pythonGenerator.ORDER_ATOMIC
  );
  var code = timer + ".stopTimer()\n";

  return code;
};

pythonGenerator["set_timer"] = function (block) {
  // Variable setter.
  const argument0 =
    pythonGenerator.valueToCode(block, "VALUE", pythonGenerator.ORDER_NONE) ||
    "0";
  const varName = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  const timer_type = block.getFieldValue("TIMER_TYPE");
  pythonGenerator.definitions_["import_time"] = "import time";
  pythonGenerator.definitions_["import_multiprocessing"] =
    "import multiprocessing";
  var code = "";

  // TODO: Hash the names, thats safer. Do not just use the name given by the user.

  if (timer_type === "CD") {
    const PYTHON_COUNT_DOWN_TIMER = `
class C${varName}:
    def __init__(self, num=0):
        self.time = num
        self.manager = multiprocessing.Manager().dict()

    def setTimer(self, num):
        self.time = num

    def _startTimer(self, manager):
        manager["time"] = self.time
        for _ in range(self.time):
            manager["time"] -= 1
            time.sleep(1/1000)

    def startTimer(self):
        self.p = multiprocessing.Process(target=self._startTimer, args=(self.manager,))
        self.p.start()

    def stopTimer(self):
        self.p.kill()

    def getTimer(self):
        return self.manager.get("time", 0)

${varName} = C${varName}(${argument0})\n`;
    code = PYTHON_COUNT_DOWN_TIMER;
  } else {
    const PYTHON_COUNT_UP_TIMER = `
class C${varName}:
    def __init__(self, num=0):
        self.time = num
        self.manager = multiprocessing.Manager().dict()

    def setTimer(self, num):
        self.time = num

    def _startTimer(self, manager):
        manager["time"] = 0
        for _ in range(self.time):
            manager["time"] += 1
            time.sleep(1/1000)

    def startTimer(self):
        self.p = multiprocessing.Process(target=self._startTimer, args=(self.manager,))
        self.p.start()

    def stopTimer(self):
        self.p.kill()

    def getTimer(self):
        return self.manager.get("time", 0)

${varName} = C${varName}(${argument0})\n`;

    code = PYTHON_COUNT_UP_TIMER;
  }
  return code;
};

pythonGenerator["get_timer"] = pythonGenerator["variables_get"];

pythonGenerator["get2_timer"] = function (block) {
  var timer = pythonGenerator.valueToCode(
    block,
    "TIMER",
    pythonGenerator.ORDER_ATOMIC
  );
  var code = timer + ".getTimer()";
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["set_gpio"] = function (block) {
  const argument0 =
    pythonGenerator.valueToCode(block, "VALUE", pythonGenerator.ORDER_NONE) ||
    "0";
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  console.log(argument0, gpio);
  var code = `gpio = return_dict["gpio"]
return_dict["gpio_before"] = gpio.copy()
gpio["${gpio}"][2] = ${argument0}
return_dict["gpio"] = gpio\n`;
  return code;
};

pythonGenerator["set_gpio2"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  const state = pythonGenerator.nameDB_.getName(
    block.getFieldValue("state"),
    Blockly.Names.NameType.VARIABLE
  ) === "ON";

  var code = `gpio = return_dict["gpio"]
return_dict["gpio_before"] = gpio.copy()
gpio["${gpio}"][2] = ${state}
return_dict["gpio"] = gpio\n`;
  return code;
};

pythonGenerator["get_gpio"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  var code = `return_dict["gpio"]["${gpio}"][2]`;
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["read_gpio"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  var code = `return_dict["gpio"]["${gpio}"][2]`;
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["read_gpio2"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  const state = pythonGenerator.nameDB_.getName(
    block.getFieldValue("state"),
    Blockly.Names.NameType.VARIABLE
  );
  var code = `return_dict["gpio"]["${gpio}"][2]`;
  if (state === "OFF") {
    code = `not return_dict["gpio"]["${gpio}"][2]`;
  }
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["wait_for_gpio"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  const state = pythonGenerator.nameDB_.getName(
    block.getFieldValue("state"),
    Blockly.Names.NameType.VARIABLE
  );

  pythonGenerator.definitions_["import_time"] = "import time";
  pythonGenerator.definitions_["import_multiprocessing"] =
    "import multiprocessing";

  // TODO: Hash the GPIO name. Thats better, more safe.
  var methodName = 'innerLoop' + gpio.replace(" ", "");
  let branch = pythonGenerator.statementToCode(block, 'DO');
  branch = pythonGenerator.addLoopTrap(branch, block) || '  pass';

  var check = `return_dict["gpio"]["${gpio}"][2]`;
  if (state === "OFF") {
    check = `not return_dict["gpio"]["${gpio}"][2]`;
  }

  var code = 'def ' + methodName + '(return_dict):\n';
  code += `  while True:
    time.sleep(.25)
    if ${check}:
    `

  var lines = branch.split('\n');
  lines.push('  break');
  branch = lines.join('\n    ');
  if (lines.length == 2) {
    branch = '  ' + branch;
  }

  code += branch;
  code += `\n
p = multiprocessing.Process(target=${methodName}, args=(return_dict,))
p.start()\n`;

  return code;
};

pythonGenerator["wait_for_gpio_block"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  const state = pythonGenerator.nameDB_.getName(
    block.getFieldValue("state"),
    Blockly.Names.NameType.VARIABLE
  );

  pythonGenerator.definitions_["import_time"] = "import time";

  var check = `return_dict["gpio"]["${gpio}"][2]`;
  if (state === "OFF") {
    check = `not return_dict["gpio"]["${gpio}"][2]`;
  }

  var code = `while True:
  time.sleep(.25)
  if ${check}:
    break\n`;
  return code;
};

pythonGenerator["wait_for_gpio_state_change_block"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );

  pythonGenerator.definitions_["import_time"] = "import time";

  var check = `return_dict["gpio"]["${gpio}"][2] != return_dict["gpio_before"]["${gpio}"][2]`;
  var code = `while True:
  time.sleep(.25)
  if ${check}:
    break\n`;
  return code;
};

pythonGenerator["wait_for_var"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  // const state = pythonGenerator.nameDB_.getName(
  //   block.getFieldValue("state"),
  //   Blockly.Names.NameType.VARIABLE
  // );
  const state = pythonGenerator.valueToCode(block, "state", pythonGenerator.ORDER_NONE) || "0";

  pythonGenerator.definitions_["import_time"] = "import time";
  pythonGenerator.definitions_["import_multiprocessing"] =
    "import multiprocessing";

  // TODO: Hash the GPIO name. Thats better, more safe.
  var methodName = 'innerLoop' + gpio.replace(" ", "");
  let branch = pythonGenerator.statementToCode(block, 'DO');
  branch = pythonGenerator.addLoopTrap(branch, block) || '  pass';

  var check = `${gpio} == ${state}`;

  var code = 'def ' + methodName + '(return_dict):\n';
  code += `  while True:
    time.sleep(.5)
    if ${check}:
    `

  var lines = branch.split('\n');
  lines.push('  break');
  branch = lines.join('\n    ');
  if (lines.length == 2) {
    branch = '  ' + branch;
  }

  code += branch;
  code += `\n
p = multiprocessing.Process(target=${methodName}, args=(return_dict,))
p.start()\n`;

  return code;
};

pythonGenerator["wait_for_var_block"] = function (block) {
  const gpio = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  // const state = pythonGenerator.nameDB_.getName(
  //   block.getFieldValue("state"),
  //   Blockly.Names.NameType.VARIABLE
  // );
  const state = pythonGenerator.valueToCode(block, "state", pythonGenerator.ORDER_NONE) || "0";

  pythonGenerator.definitions_["import_time"] = "import time";
  pythonGenerator.definitions_["import_multiprocessing"] =
    "import multiprocessing";

  var check = `${gpio} == ${state}`;

  var code = `while True:
  time.sleep(.5)
  if ${check}:
    break\n`;
  return code;
};

pythonGenerator["analog_map"] = function (block) {
  const X = pythonGenerator.nameDB_.getName(
    block.getFieldValue("VAR"),
    Blockly.Names.NameType.VARIABLE
  );
  var from1 = block.getFieldValue('FROM1');
  var from2 = block.getFieldValue('FROM2');
  var to1 = block.getFieldValue('TO1');
  var to2 = block.getFieldValue('TO2');

  var code = `(${X} or 0) / (${from2} - ${from1}) * (${to2} - ${to1})`;
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["logical_and_plus_minus"] = function (block) {
  const elements = new Array(block.itemCount_);
  for (let i = 0; i < block.itemCount_; i++) {
     elements[i] = pythonGenerator.valueToCode(block, 'ADD' + i, pythonGenerator.ORDER_NONE) || 'None';
  }

  const code = elements.join(' and ');
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator["logical_or_plus_minus"] = function (block) {
  const elements = new Array(block.itemCount_);
  for (let i = 0; i < block.itemCount_; i++) {
     elements[i] = pythonGenerator.valueToCode(block, 'ADD' + i, pythonGenerator.ORDER_NONE) || 'None';
  }

  const code = elements.join(' or ');
  return [code, pythonGenerator.ORDER_NONE];
};

pythonGenerator['controls_until_multiprocessing'] = function(block) {
  pythonGenerator.definitions_["import_time"] = "import time";
  pythonGenerator.definitions_["import_multiprocessing"] =
    "import multiprocessing";

  let argument0 = pythonGenerator.valueToCode(
                      block, 'BOOL',
                      pythonGenerator.ORDER_LOGICAL_NOT) ||
      'False';
  let branch = pythonGenerator.statementToCode(block, 'DO');
  branch = pythonGenerator.addLoopTrap(branch, block) || pythonGenerator.PASS;
  var lines = branch.split('\n');
  branch = lines.join('\n  ');
  if (lines.length == 2) {
    branch = '  ' + branch;
  }

  argument0 = 'not ' + argument0;
  let code = `def run(return_dict):
  while ` + argument0 + `:\n  ` + branch;

  code += '\n';
  code += 'p = multiprocessing.Process(target=run, args=(return_dict,))\n';
  code += 'p.start()\n';
  code += 'while ' + argument0 + ':\n' + '  time.sleep(.25)\np.kill()';
  return code;
};

pythonGenerator['controls_whileUntil'] = function(block) {
  const until = block.getFieldValue('MODE') === 'UNTIL';
  let argument0 = pythonGenerator.valueToCode(
                      block, 'BOOL',
                      until ? pythonGenerator.ORDER_LOGICAL_NOT : pythonGenerator.ORDER_NONE) ||
      'False';
  let branch = pythonGenerator.statementToCode(block, 'DO');
  branch = pythonGenerator.addLoopTrap(branch, block) || pythonGenerator.PASS;
  if (until) {
    argument0 = 'not ' + argument0;
  }
  var code = `while True:
  time.sleep(.25)
  if ${argument0}:
    while ${argument0}:\n    `;
  var lines = branch.split('\n');
  branch = lines.join('\n    ');
  if (lines.length == 2) {
    branch = '    ' + branch;
  }
  return code + branch;
};
