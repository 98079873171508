import React from 'react';


const Empty = (props) => {
  return (
    <>
      <div className="flex w-full justify-center h-full justify-around items-center flex-col">
        <progress className="progress progress-primary w-56"></progress>
        <a style={{color: "gray"}} href="/login">Login</a>
      </div>
    </>
  )
}
export default Empty;
