import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from "./config.js";
import UserService from "./service.js";

const Login = (props) => {
  const userService = new UserService();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");

  let navigate = useNavigate();

  function login() {
    var form = new FormData();
    form.append("email", email);
    form.append("password", password);

    userService
      .login(form)
      .then((resp) => {
        resp.json().then((json) => {
          console.log(json);
          if (!("error" in json)) {
            window.location.reload(false);
          } else {
            setError(json["error"]);
          }
        });
      })
      .catch(function () {
        setError("no internet connection");
      });
  }

  useEffect(() => {});

  return (
    <>
      <div className="flex w-full justify-center">
        <div className="card w-96 bg-base-100 shadow-xl my-10">
          <figure className="px-10 pt-10">
            <img
              className="mask mask-hexagon-2"
              src={IP + "/static/lokodio_logo.png"}
              alt="LoKoDIO Logo"
              width="100"
            />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Welcome back.</h2>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <label className="input-group">
                <span>&middot;</span>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  placeholder="info@site.com"
                  className="input input-bordered"
                />
              </label>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Password</span>
              </label>
              <label className="input-group">
                <span>&middot;</span>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="··············"
                  className="input input-bordered"
                />
              </label>
            </div>

            <div className="card-actions">
              <button onClick={() => login()} className="btn btn-primary">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
      {error ? (
        <div className="toast">
          <div className="alert alert-error">
            <div>
              <span>Error: {error}</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Login;
