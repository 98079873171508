import React, { useState, useEffect, useCallback } from "react";
import UserService from "./service.js";
import WorkspaceMenu from "./WorkspaceMenu.js";
import { BlocklyWorkspace } from "react-blockly";
import Blockly from "blockly";
import CustomToolbox from "./custom_blocks";

const MarketPlace = (props) => {
  const [xml, setXml] = useState();

  return (
    <>
      <WorkspaceMenu />
      <div
        className="preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-center justify-center gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full"
        style={{
          backgroundSize: "5px 5px",
          backgroundImage:
            "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)",
          zIndex: 0,
        }}
      >
        <BlocklyWorkspace
          className="block w-full h-full"
          initialXml={xml}
          onXmlChange={setXml}
          // onWorkspaceChange={(e) => handleWorkspaceChange(e)}
          // onInject={(e) => handleOnInject(e)}
          toolboxConfiguration={CustomToolbox}
        />
      </div>
    </>
  );
};

export default MarketPlace;
