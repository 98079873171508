import React, { useEffect, useState } from "react";
import { BlocklyWorkspace } from "react-blockly";
import Toolbox from "../toolbox.js";
import { v4 as uuid } from "uuid";

const DEFAULT_TAB_ID = "default-tab-id-automatika-123";

const EditableTabs = (props) => {
  const [tabs, setTabs] = useState([]);
  const [currentLocalTab, setCurrentLocalTab] = useState();
  const [editTabNameMode, setEditTabNameMode] = useState(false);
  const [deleteTabMode, setDeleteTabMode] = useState(false);

  const handleDoubleClick = () => {
    setEditTabNameMode(true);
  };

  const handleEditTabName = (e) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.id === currentLocalTab.id) {
        return {
          ...tab,
          name: e.target.value,
        };
      } else {
        return tab;
      }
    });
    setTabs(updatedTabs);
    setCurrentLocalTab({ ...currentLocalTab, name: e.target.value });
    props.setCurrentTab({ ...currentLocalTab, name: e.target.value });
  };

  const handleOnBlur = () => {
    setEditTabNameMode(false);
  };

  const handleSelectTab = (tab) => {
    setCurrentLocalTab(tab);
    props.setCurrentTab(tab);
    setEditTabNameMode(false);
  };

  const handleAddTab = () => {
    const tempId = uuid();
    const newTabObject = {
      id: tempId,
      name: `Untitled ${tabs.length}`,
      content: (
        <div className="blocklyDiv" id={"blocklyWorkspaceTabNo_" + tempId}>
          <BlocklyWorkspace
            className="block h-full"
            onWorkspaceChange={(e) => props.handleWorkspaceChange(e)}
            onInject={(e) => props.handleOnInject(e)}
            workspaceConfiguration={{
              zoom: {
                controls: true,
                wheel: true,
                startScale: 1,
                maxScale: 3,
                minScale: 0.3,
                scaleSpeed: 1.2,
              },
              readOnly: props.read_only,
            }}
            toolboxConfiguration={props.read_only ? undefined : Toolbox}
            initialXml={props.xml}
            onXmlChange={props.setXml}
          />
        </div>
      ),
    };
    setTabs([...tabs, newTabObject]);
    setCurrentLocalTab(newTabObject);
    props.setCurrentTab(newTabObject);
    setEditTabNameMode(false);
  };

  const handleDeleteTab = (tabToDelete) => {
    const tabToDeleteIndex = tabs.findIndex((tab) => tab.id === tabToDelete.id);

    const updatedTabs = tabs.filter((tab, index) => {
      return index !== tabToDeleteIndex;
    });

    const previousTab =
      tabs[tabToDeleteIndex - 1] || tabs[tabToDeleteIndex + 1] || {};

    setTabs(updatedTabs);
    setCurrentLocalTab(previousTab);
    props.setCurrentTab(previousTab);
    setEditTabNameMode(false);
    setDeleteTabMode(false);
  };

  const createTabs = () => {
    const allTabs = tabs.map((tab) => {
      return (
        <div
          key={tab.id}
          className={
            "tab tab-lifted" +
            (currentLocalTab.id === tab.id ? " tab-active" : "")
          }
          onClick={() => handleSelectTab(tab)}
        >
          {editTabNameMode && currentLocalTab.id === tab.id ? (
            <input
              defaultValue={tab.name}
              onBlur={handleOnBlur}
              onChange={handleEditTabName}
            />
          ) : (
            <div className="grid grid-cols-2 gap-4 justify-items-stretch">
              <button onDoubleClick={() => handleDoubleClick(tab)}>
                {tab.name}
              </button>
              {currentLocalTab.id === tab.id && tab.id !== 0 ? (
                <button
                  className="justify-self-end"
                  onClick={() => setDeleteTabMode(true)}
                >
                  x
                </button>
              ) : null}
            </div>
          )}
        </div>
      );
    });

    return (
      <div className="tabs">
        {allTabs}
        <div className="tab tab-lifted ">
          <button className="text-2xl" onClick={handleAddTab}>
            +
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (deleteTabMode) {
      handleDeleteTab(currentLocalTab);
    }
  }, [currentLocalTab, deleteTabMode]);

  useEffect(() => {
    if (currentLocalTab) {
      props.refBlocklyDiv.current = document.getElementById(
        "blocklyWorkspaceTabNo_" + currentLocalTab.id
      );
    }
  }, [currentLocalTab, props.refBlocklyDiv]);
  // Add the workspace to the current tab
  useEffect(() => {
    console.log(props.tabsIds);
    if (props.tabsIds.length !== 0) {
      var temptabs = [];
      props.tabsIds.forEach((tab, i) => {
        const newTabObject = {
          id: tab[0], // tab id from db
          name: tab[1], // tab name from db
          content: (
            <div
              className="blocklyDiv"
              ref={props.refBlocklyDiv}
              id={"blocklyWorkspaceTabNo_" + tab[0]}
            >
              <BlocklyWorkspace
                className="block  h-full"
                onWorkspaceChange={(e) => props.handleWorkspaceChange(e)}
                onInject={(e) => props.handleOnInject(e)}
                workspaceConfiguration={{
                  zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 1,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2,
                  },
                  readOnly: props.read_only,
                }}
                toolboxConfiguration={props.read_only ? undefined : Toolbox}
                initialXml={props.xml}
                onXmlChange={props.setXml}
              />
            </div>
          ),
        };
        // props.refBlocklyDiv.current = document.getElementById(
        //   "blocklyWorkspaceTabNo_" + defaultTabId
        // );
        temptabs = [...temptabs, newTabObject];
      });

      setTabs(temptabs);
      setCurrentLocalTab(tabs[0]);
      props.setCurrentTab(tabs[0]);
      setEditTabNameMode(false);
    } else {
      const newTabObject = {
        id: DEFAULT_TAB_ID,
        name: "Default",
        content: (
          <div
            className="blocklyDiv"
            ref={props.refBlocklyDiv}
            id={"blocklyWorkspaceTabNo_" + DEFAULT_TAB_ID}
          >
            <BlocklyWorkspace
              className="block  h-full"
              onWorkspaceChange={(e) => props.handleWorkspaceChange(e)}
              onInject={(e) => props.handleOnInject(e)}
              workspaceConfiguration={{
                zoom: {
                  controls: true,
                  wheel: true,
                  startScale: 1,
                  maxScale: 3,
                  minScale: 0.3,
                  scaleSpeed: 1.2,
                },
                readOnly: props.read_only,
              }}
              toolboxConfiguration={props.read_only ? undefined : Toolbox}
              initialXml={props.xml}
              onXmlChange={props.setXml}
            />
          </div>
        ),
      };
      // props.refBlocklyDiv.current = document.getElementById(
      //   "blocklyWorkspaceTabNo_" + defaultTabId
      // );
      setTabs([...tabs, newTabObject]);
      setCurrentLocalTab(newTabObject);
      props.setCurrentTab(newTabObject);
      setEditTabNameMode(false);
    }
  }, [props.tabsIds]);
  return (
    <div className="container">
      <div className="well">
        {/* <button className="add-tab-button" onClick={handleAddTab}>
          <i className="text-primary fas fa-plus-square" /> Add Tab
        </button> */}
        {createTabs()}
        <div className="tab-content">
          {currentLocalTab ? currentLocalTab.content : null}
        </div>
      </div>
    </div>
  );
};

export default EditableTabs;
