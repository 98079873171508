import React, { useState } from "react";
import { IP } from "../config";
import validator from "validator";

const InviteByEmailModal = (props) => {
  const [recipientEmail, setRecipientEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState();
  const [error, setError] = useState("");

  function handleNewEmailChange(e) {
    setIsValidEmail(validator.isEmail(e.target.value));
    validator.isEmail(e.target.value)
      ? setError("")
      : setError("Email is Not Valid");
    setRecipientEmail(e.target.value);
  }

  function sendEmailInvitation(e) {
    if (recipientEmail !== "") {
      var form = new FormData();
      form.append("recipient_email", recipientEmail);
      // alert(recipientEmail);
      fetch(IP + "/api/sendemailinvite", {
        method: "POST",
        mode: "cors",
        body: form,
        credentials: "include",
      }).then((resp) => {
        handleModalClose();
      });
    }
  }

  function handleModalClose() {
    setRecipientEmail("");
    setError("");
    setIsValidEmail(null);
    props.handleModalClose();
  }

  return (
    <>
      <input
        type="checkbox"
        checked={props.showModal}
        id="rename-gpio"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box ">
          <h3 className="font-bold text-lg">Invite A Friend</h3>
          <button
            htmlFor="rename-gpio"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={handleModalClose}
          >
            ✕
          </button>
          <div className="flex flex-col mt-20">
            <input
              type="text"
              className={
                "border border-gray-700 p-2 rounded mb-5" +
                (isValidEmail ? "" : " input-error")
              }
              placeholder="Enter Email to Send Invite"
              value={recipientEmail}
              onChange={(e) => handleNewEmailChange(e)}
            />

            <small className="text-error">{error}</small>
          </div>
          <div className="modal-action">
            <button
              className="btn"
              disabled={!isValidEmail}
              onClick={sendEmailInvitation}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default InviteByEmailModal;
