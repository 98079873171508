import { IP } from "./config.js";

class UserService {
  // User Authentication

  async registerUser(data) {
    return await fetch(IP + "/api/register ", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
  }

  async login(data) {
    return await fetch(IP + "/api/login ", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
  }

  async checkLogin() {
    const res = await fetch(IP + "/api/checklogin", {
      method: "POST",
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  logout() {
    fetch(IP + "/api/logout", {
      method: "POST",
      mode: "cors",
      credentials: "include",
    });
  }

  // Code evaluation and Managment
  cancelCodeEval() {
    fetch(IP + "/api/eval/cancel", {
      method: "POST",
      mode: "cors",
      credentials: "include",
    });
  }

  async getCodeEval(data, signal) {
    const res = await fetch(IP + "/api/eval", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
      signal: signal,
    });
    return await res.json();
  }

  // GPIO state managment
  async getGpioState(signal) {
    const res = await fetch(IP + "/api/eval/gpiostate", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      signal: signal,
    });
    return await res.json();
  }

  async setGpioState(data) {
    const res = await fetch(IP + "/api/setgpiostate", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }
  // Project Managment
  async getAllProjects() {
    const res = await fetch(IP + "/api/proj/getall", {
      method: "POST",
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async getProject(data) {
    const res = await fetch(IP + "/api/proj/get", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async saveProject(data) {
    const res = await fetch(IP + "/api/proj/save", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async updateProject(data) {
    const res = await fetch(IP + "/api/proj/update", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async deleteProject(data) {
    return await fetch(IP + "/api/proj/delete", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
  }

  async getDefaultProject(data) {
    const res = await fetch(IP + "/api/proj/get_default", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }
  // Variable Management
  async getAllProjectVariable(data) {
    const res = await fetch(IP + "/api/proj/variables/getall", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async getProjectVariable(data) {
    const res = await fetch(IP + "/api/proj/variables/get", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async saveProjectVariable(data) {
    const res = await fetch(IP + "/api/proj/variables/save", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async updateProjectVariable(data) {
    const res = await fetch(IP + "/api/proj/variables/update", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  async deleteProjectVariable(data) {
    const res = await fetch(IP + "/api/proj/variables/delete", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }

  // Send Emails

  async sendEmailInvite(data) {
    const res = await fetch(IP + "/api/sendemailinvite ", {
      method: "POST",
      body: data,
      mode: "cors",
      credentials: "include",
    });
    return await res.json();
  }
}

export default UserService;
