import React, { useRef, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import WorkspaceMenu from "./WorkspaceMenu.js";
import UserService from "./service.js";
import Blockly from "blockly";
import { pythonGenerator } from "blockly/python";
import debounce from "lodash.debounce";
import "./custom_blocks.js";
import timerFlyoutCallback from "./timer_category.js";
import GPIOFlyoutCallback from "./gpio_category.js";
import AbortController from "abort-controller";
import { ZoomToFitControl } from "@blockly/zoom-to-fit";
import { Backpack } from "@blockly/workspace-backpack";
import "@blockly/block-plus-minus";
import { Swap } from "react-daisyui";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditableTabs from "./helpers/EditableTabs.js";
import { v4 as uuid } from "uuid";
import { loops } from "blockly/blocks";
import { WorkspaceSearch } from "@blockly/plugin-workspace-search";
import { IP } from "./config";

Blockly.Msg["TEXT_JOIN_TITLE_CREATEWITH"] = "concatenate";
Blockly.VerticalFlyout.prototype.getFlyoutScale = function () {
  return 1;
};
loops.loopTypes.add("wait_for_gpio");

//-----------------------------------------------------------------------------
// ---Stateless objects to use when using functions outside of reacts scope ---
//-----------------------------------------------------------------------------
let backpackStateLessObj = null;
let projStateLessObj = [];
let gpioStateLessObj = {
  GPIO0: [1, "GPIO0", false, "in", "D", true],
  GPIO1: [2, "GPIO1", false, "in", "D", true],
  GPIO2: [4, "GPIO2", false, "in", "D", true],
  GPIO3: [5, "GPIO3", false, "in", "D", true],
  GPIO4: [6, "GPIO4", false, "in", "D", true],
  GPIO5: [7, "GPIO5", false, "in", "D", true],
  GPIO6: [9, "GPIO6", false, "in", "D", true],
  GPIO7: [10, "GPIO7", false, "in", "D", true],
  GPIO8: [11, "GPIO8", false, "in", "D", true],
  GPIO9: [12, "GPIO9", false, "in", "D", true],
  GPIO10: [14, "GPIO10", false, "in", "D", true],
  GPIO11: [15, "GPIO11", false, "in", "D", true],
  GPIO12: [16, "GPIO12", false, "in", "D", true],
  GPIO13: [17, "GPIO13", false, "in", "D", true],
  GPIO14: [19, "GPIO14", false, "in", "D", true],
  GPIO15: [20, "GPIO15", false, "in", "D", true],
  GPIO16: [21, "GPIO16", false, "in", "D", true],
  GPIO17: [22, "GPIO17", false, "in", "D", true],
  GPIO18: [24, "GPIO18", false, "in", "D", true],
  GPIO19: [25, "GPIO19", false, "in", "D", true],
  GPIO20: [26, "GPIO20", false, "in", "D", true],
  GPIO21: [27, "GPIO21", false, "in", "D", true],
  GPIO22: [29, "GPIO22", false, "in", "D", true],
  GPIO26: [31, "GPIO26", false, "in", "A", true],
  GPIO27: [32, "GPIO27", false, "in", "A", true],
  GPIO28: [34, "GPIO28", false, "in", "A", true],
};
let currentTabStateLessObj = gpioStateLessObj; // TODO: Might need to do a deep copy?
//-----------------------------------------------------------------------------

let controller = new AbortController();
let signal = controller.signal;
const initialTabs = {
  gpio: true,
  variables: false,
  timers: false,
};
let refEvalShown = false;

const WorkspaceReact = (props) => {
  const userService = new UserService();

  const [xml, setXml] = useState();
  const [proj, setProj] = useState();
  const [projTabData, setProjTabData] = useState([]);
  const [name, setName] = useState();
  const [workspace, setWorkspace] = useState();
  const [code, setCode] = useState("");
  const [eval_, setEval] = useState("");
  const [codeShown, setCodeShown] = useState(false);
  const [evalShown, setEvalShown] = useState(false);
  const [analogEditable, setAnalogEditable] = useState(false);
  const [isGpioTabNotLock, setIsGpioTabNotLock] = useState(true);
  const [gpio, setGpio] = useState({});
  const [gpioKeysSorted, setGpioKeysSorted] = useState([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [tempKey, setTempKey] = useState();
  const [tempPin, setTempPin] = useState([]);
  const [tabs, setTabs] = useState(initialTabs);
  const [variableList, setVariableList] = useState([]);
  const [timerList, setTimerList] = useState([]);
  const [tabsIds, setTabsIds] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const [editVarValue, setEditVarValue] = useState(false);
  let { id } = useParams();

  const refBlocklyArea = useRef(null);
  const refBlocklyDiv = useRef(null);
  const refResizerHorizontal = useRef(null);
  const refResizerVertical = useRef(null);
  const refIOWindow = useRef(null);
  const refCodeWindow = useRef(null);
  const refBlocklyAndIOWindow = useRef(null);
  const blocklyArea = document.getElementById("blocklyArea");

  const onresize = useCallback(function () {
    const injectionDiv = document.getElementsByClassName("injectionDiv");
    if (injectionDiv && refBlocklyDiv.current) {
      const blocklyDivEl = refBlocklyDiv.current;
      // Compute the absolute coordinates and dimensions of blocklyArea.
      let element = blocklyArea;
      let x = 0;
      let y = 0;
      do {
        x += element.offsetLeft;
        y += element.offsetTop;
        element = element.offsetParent;
      } while (element);
      // Position blocklyDiv over blocklyArea.

      blocklyDivEl.style.left = x + "px";
      blocklyDivEl.style.top = y - 30 + "px";
      blocklyDivEl.style.width =
        window.getComputedStyle(blocklyDivEl).width + "px";
      blocklyDivEl.style.height =
        window.getComputedStyle(blocklyDivEl).height + "px";

      injectionDiv.offsetLeft = x + "px";
      injectionDiv.offsetTop = y + "px";
      injectionDiv.offsetWidth = blocklyArea.offsetWidth + "px";
      injectionDiv.offsetHeight = blocklyArea.offsetHeight + "px";
      Blockly.svgResize(Blockly.getMainWorkspace());
    }
  });

  const showToastMessage = (message, type) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: localStorage.getItem("theme") === "business" ? "dark" : "light",
    });
  };

  const handleAnalogEditClick = () => {
    setAnalogEditable(!analogEditable);
  };

  function updateGpio(k, newPin, checkWorkSpace = true) {
    var form = new FormData();
    let payload;
    const renameGpio = gpioStateLessObj[k][1] !== newPin[1];
    console.log("rename?", renameGpio);

    var exists = false;

    var newname = newPin[1];

    Object.entries(gpioStateLessObj).map(([k, pin]) => {
      if (newname.toUpperCase() == pin[1].toUpperCase()) {
        exists = true;
      }
    });

    if (exists && renameGpio) {
      alert("GPIO with this name already exists.");
      return;
    }

    if (renameGpio) {
      //replace dashes '-' and spaces with underscores '_'
      newPin[1] = newPin[1]
        .replaceAll(" ", "_")
        .replaceAll("-", "_")
        .toUpperCase();
      payload = { ...gpioStateLessObj, [k]: newPin };
      payload[newPin[1]] = payload[k];
      delete payload[k];
      // Check if we need to rename the variable in the workspace
      if (checkWorkSpace) {
        if (workspace.getVariable(gpioStateLessObj[k][1], "GPIO")) {
          workspace.renameVariableById(
            workspace.getVariable(gpioStateLessObj[k][1], "GPIO")["id_"],
            newPin[1]
          );
        }
      }
    } else {
      payload = { ...gpioStateLessObj, [k]: newPin };
    }
    console.log(">>>>>>>>>>>>>>>>>>>>>> UPDATE", payload);
    form.append("gpio", JSON.stringify(payload));
    form.append("id", id);
    userService.setGpioState(form).then((json) => {
      // fetchGpioState();
      var result = Object.entries(payload).sort((a, b) => a[1][0] - b[1][0]);
      setGpio(payload);
      setGpioKeysSorted(result);
      gpioStateLessObj = payload;

      if (showRenameModal) setShowRenameModal(false);
    });
  }
  function handleOnEditVarValueBlur(e, key) {
    console.log(e, key);
    updateVariableInDB(key, "value", e.target.value);
    setEditVarValue(false);
  }
  function handleOnEditVarValueDoubleClick() {
    setEditVarValue(true);
  }

  function workspaceChangeListener(event) {
    console.log("************************************", event);
    if (event.recordUndo !== false) {
      // TODO: this is just a work around to prevent variables to be delete or added after every workspace change
      switch (event.type) {
        case Blockly.Events.VAR_RENAME:
          if (event.oldName !== event.newName) {
            // if the variable name is actually changed
            const newVar = Blockly.getMainWorkspace().getVariableById(
              event.varId
            );
            console.log("new variable rename: ", newVar);
            if (newVar.type === "GPIO") {
              onGpioVarRename(event);
            } else {
              updateVariableInDB(event.varId, "name", event.newName);
            }
          }
          break;

        case Blockly.Events.VAR_CREATE:
          console.log("VAR_CREATE Event Object: ", event);
          if (event.varType !== "GPIO") {
            // to store variables other than gpio
            onNewVarCreate(event);
          }
          break;

        case Blockly.Events.VAR_DELETE:
          console.log("VAR_DELETE Event Object: ", event);
          if (event.varType !== "GPIO") {
            // to delete variables other than gpio
            deleteVariableInDB(event.varId);
          }
          break;

        default:
          break;
      }
    }
  }

  function onNewVarCreate(event) {
    var form = new FormData();
    form.append("proj_id", id);
    form.append("var_id", event.varId);
    form.append("name", event.varName);
    form.append("type", event.varType);
    userService.saveProjectVariable(form).then((res) => {
      if (res) {
        console.log("Variable added to DB");
        getAllVariablesFromDB();
      } else {
        console.log("Error in saving");
      }
    });
  }

  function getAllVariablesFromDB() {
    let varList = [];
    let timList = [];
    var form = new FormData();
    form.append("proj_id", id);
    userService.getAllProjectVariable(form).then((variables) => {
      variables.forEach((item) => {
        if (item.type === "Timer") {
          timList.push(item);
        } else {
          varList.push(item);
        }
      });
      setVariableList(varList);
      setTimerList(timList);
    });
    return varList.concat(timList);
  }

  function handleEditVarValue(e) {}

  function updateVariableInDB(varId, whatToUpdate, updateValue) {
    var form = new FormData();
    form.append("proj_id", id);
    form.append("var_id", varId);
    form.append("columnName", whatToUpdate);
    form.append("valueToUpdate", updateValue);
    userService.updateProjectVariable(form).then((res) => {
      if (res >= 0) {
        console.log("Variable updated in DB");
        getAllVariablesFromDB();
      } else {
        console.log("Error in saving");
      }
    });
  }

  function deleteVariableInDB(varId) {
    var form = new FormData();
    form.append("proj_id", id);
    form.append("var_id", varId);
    userService.deleteProjectVariable(form).then((res) => {
      if (res >= 0) {
        console.log("Variable deleted in DB");
        getAllVariablesFromDB();
      } else {
        console.log("Error in deleting");
      }
    });
  }

  function onGpioVarRename(event) {
    var exists = false;

    var newname = event.newName.toUpperCase();

    Object.entries(gpioStateLessObj).map(([k, pin]) => {
      if (newname == pin[1].toUpperCase()) {
        exists = true;
      }
    });

    if (!exists) {
      Object.entries(gpioStateLessObj).map(([k, pin]) => {
        var derivedVarId = "_gpio_" + pin[0].toString();
        if (derivedVarId === event.varId) {
          var newPin = Object.values({ ...pin, 1: newname });
          updateGpio(k, newPin, false);
          setGpio({ ...gpio, [k]: newPin });
        }
      });
    } else {
      alert("GPIO with that name already exists.");
    }
  }

  function saveProject(saveAs = false, new_name = "") {
    var form = new FormData();
    if (saveAs) {
      // if saving as a new or duplicate project
      form.append("name", new_name);
      form.append("project_str", JSON.stringify(projStateLessObj)); // Save project along with tab data
    } else {
      form.append("name", name);
      form.append("project_str", "");
    }

    userService.saveProject(form).then((json) => {
      setProj(json);
      window.location.href = "/" + json;
    });
  }

  function updateProject(project_str) {
    var form = new FormData();
    form.append("id", id);
    form.append("name", name);
    form.append("project_str", JSON.stringify(project_str));
    form.append("gpiostate", JSON.stringify(gpioStateLessObj));
    if (backpackStateLessObj) {
      if (backpackStateLessObj.getCount() > 0) {
        form.append(
          "backpack",
          JSON.stringify(backpackStateLessObj.getContents())
        );
        console.log("BackPack: ", backpackStateLessObj.getContents());
      }
    }
    // console.log("project str: ", JSON.stringify(project_str));
    userService.updateProject(form).then((json) => {});
  }

  const debouncedHandleWorkspaceChange = useCallback(
    debounce((e) => {
      console.log(" #  ", e.isDragging());

      if (!e.isDragging()) {
        console.log("change in workspace", e);
        setWorkspace(e); // for saveAs feature
        // const code = pythonGenerator.workspaceToCode(e);
        const serializedWorkspace = Blockly.serialization.workspaces.save(e);
        var ids = [];
        for (let [key, value] of Blockly.getMainWorkspace().blockDB) {
          ids.push(value.id);
        }
        serializedWorkspace["ids"] = ids;
        if (!id.startsWith("default") && currentTabStateLessObj) {
          // To prevent updating the default projects
          var proj_str = projStateLessObj;
          if (proj_str.length !== 0) {
            // if not empty or null
            proj_str = proj_str.map((data) => {
              if (data.tab_id === currentTabStateLessObj.id) {
                data.tab_name = currentTabStateLessObj.name;
                data.workspace = serializedWorkspace;
              }
              return data;
            });
          } else {
            // if proj_str is empty
            proj_str = [
              {
                tab_id: currentTabStateLessObj.id,
                tab_name: currentTabStateLessObj.name,
                workspace: serializedWorkspace,
              },
            ];
          }
          projStateLessObj = proj_str;
          setProjTabData(projStateLessObj);
          updateProject(projStateLessObj);
        }
      }
    }, 500), // 1000 -> 500 to accomodate the saving of workspace when changing tabs
    []
  );

  function handleWorkspaceChange(e) {
    console.log(
      "handleWorkspaceChange",
      projStateLessObj,
      currentTabStateLessObj
    );
    debouncedHandleWorkspaceChange(e);

    if (!refEvalShown) {
      setCode(genCode());
    }
  }

  function handleOnInject(e) {
    e.registerToolboxCategoryCallback("TIMER", timerFlyoutCallback);
    e.registerButtonCallback("create_new_timer", function (button) {
      Blockly.Variables.createVariableButtonHandler(
        button.getTargetWorkspace(),
        null,
        "Timer"
      );
    });
    e.registerToolboxCategoryCallback("GPIO", GPIOFlyoutCallback);
    e.registerToolboxCategoryCallback("VARIABLE", (workspace) => {
      var blocklist = Blockly.Variables.flyoutCategory(workspace);
      if (blocklist.length > 1) {
        const block = Blockly.utils.xml.createElement("block");
        block.setAttribute("type", "wait_for_var");
        block.appendChild(
          Blockly.Variables.generateVariableFieldDom(
            workspace.getVariablesOfType("")[0]
          )
        );
        const value = Blockly.utils.xml.textToDom(
          '<value name="state">' +
            '<shadow type="math_number">' +
            '<field name="NUM">1</field>' +
            "</shadow>" +
            "</value>"
        );
        block.appendChild(value);

        const block2 = Blockly.utils.xml.createElement("block");
        block2.setAttribute("type", "wait_for_var_block");
        block2.appendChild(
          Blockly.Variables.generateVariableFieldDom(
            workspace.getVariablesOfType("")[0]
          )
        );
        const value2 = Blockly.utils.xml.textToDom(
          '<value name="state">' +
            '<shadow type="math_number">' +
            '<field name="NUM">1</field>' +
            "</shadow>" +
            "</value>"
        );
        block2.appendChild(value2);

        const block3 = Blockly.utils.xml.createElement("block");
        block3.setAttribute("type", "analog_map");
        block3.appendChild(
          Blockly.Variables.generateVariableFieldDom(
            workspace.getVariablesOfType("")[0]
          )
        );

        blocklist.push(block);
        blocklist.push(block2);
        blocklist.push(block3);
      }
      return blocklist;
    });

    setWorkspace(e);
    const variables = getAllVariablesFromDB();
    variables.forEach((variable) => {
      if (!e.getVariableById(variable.var_id)) {
        e.createVariable(variable.name, variable.type, variable.var_id);
      }
    }); // to keep variables same across workspace tabs
    e.addChangeListener(workspaceChangeListener);
    const zoomToFit = new ZoomToFitControl(e);
    zoomToFit.init();
    const workspaceSearch = new WorkspaceSearch(e);
    workspaceSearch.init();
    workspaceSearch.open();
    const backpackOptions = {
      allowEmptyBackpackOpen: true,
      useFilledBackpackImage: true,
      contextMenu: {
        emptyBackpack: true,
        removeFromBackpack: true,
        copyToBackpack: true,
      },
    };
    const backpack = new Backpack(e, backpackOptions);
    backpack.init();
    backpackStateLessObj = backpack;

    let intervalRunCount = 0;
    const gpioStateLessObjInterval = setInterval(() => {
      intervalRunCount++;
      if (gpioStateLessObj) {
        console.log("initial gpio", gpioStateLessObj);
        const gpioVarList = e.getVariablesOfType("GPIO");

        if (gpioVarList.length !== 0) {
          // If gpio type variables already exist
          // let gpioPinAndName =null;
          const gpioObjectCopy = {};
          let gpioVarListObj = null;
          let renameRequired = false;
          gpioVarListObj = Object.fromEntries(
            Object.entries(gpioVarList).map(([key, variable]) => [
              variable.getId(),
              variable.name,
            ])
          );
          Object.entries(gpioStateLessObj).map(([k, pin]) => {
            if (pin[5]) {
              try {
                const derivedVarId = "_gpio_" + pin[0];
                // Check if the variable exists w.r.t the gpio pin
                if (Object.hasOwn(gpioVarListObj, derivedVarId)) {
                  // Check if gpio needs renaming
                  if (pin[1] !== gpioVarListObj[derivedVarId]) {
                    renameRequired = true;
                    const newPin = pin;
                    newPin[1] = gpioVarListObj[derivedVarId]
                      .replaceAll(" ", "_")
                      .replaceAll("-", "_");
                    Object.assign(gpioObjectCopy, { [newPin[1]]: newPin });
                    // gpioObjectCopy = { ...gpioObjectCopy, [k]: newPin };
                    // gpioObjectCopy[newPin[1]] = gpioObjectCopy[k];
                    // if (Object.hasOwn(gpioObjectCopy, newPin[1])) {
                    //   alert("Deleting ", gpioObjectCopy[k]);
                    //   delete gpioObjectCopy[k];
                    // }
                    // updateGpio(k, newPin, false);
                  } else {
                    Object.assign(gpioObjectCopy, { [k]: pin });
                  }
                } else {
                  // If variable does not exist then create it with default name
                  Object.assign(gpioObjectCopy, { [k]: pin });
                  e.createVariable(
                    pin[1].toString(),
                    "GPIO",
                    "_gpio_" + pin[0].toString()
                  );
                }
              } catch (err) {
                console.log(err);
              }
            } else {
              Object.assign(gpioObjectCopy, { [k]: pin });
            }
          });
          if (renameRequired) {
            var form = new FormData();
            console.log("Sending data to be modified...", gpioObjectCopy);
            form.append("gpio", JSON.stringify(gpioObjectCopy));
            form.append("id", id);
            userService.setGpioState(form).then((json) => {
              // fetchGpioState();
            });
          } else {
            var form = new FormData();
            form.append("gpio", JSON.stringify(gpioStateLessObj));
            form.append("id", id);
            fetch(IP + "/api/setgpiostate", {
              method: "POST",
              body: form,
              mode: "cors",
              credentials: "include",
            })
              .then((resp) => {
                resp.json().then((json) => {
                  // fetchGpioState();
                });
              })
              .catch((err) => {
                console.log("Update GPIO error", err);
                alert("Error occured while updating GPIO state");
              });
          }
        } else {
          // If new project and all the gpio variables need to be created from scratch
          Object.entries(gpioStateLessObj).map(([k, pin]) => {
            try {
              if (pin[5]) {
                e.createVariable(
                  pin[1].toString(),
                  "GPIO",
                  "_gpio_" + pin[0].toString()
                );
              }
            } catch (err) {
              console.log(err);
            }
          });
        }
        clearInterval(gpioStateLessObjInterval);
        setTimeout(() => {
          // fetchGpioState();
        }, 2000);
      } else if (intervalRunCount >= 5) {
        // if API is not working then clears interval after trying 5 times
        clearInterval(gpioStateLessObjInterval);
        alert("Failed to set GPIO variables");
      }
    }, 200);
  }

  function genCode() {
    const code = pythonGenerator.workspaceToCode(Blockly.getMainWorkspace());
    // console.log("CODE", code);
    return code;
  }

  function handleCodeGen() {
    setEvalShown(false);
    refEvalShown = false;
    setCodeShown(true);

    const code = genCode();

    setEval("");
    setCode(code);

    return code;
  }

  function handleCancel() {
    controller.abort();
    setEval(<span className="text-error">Aborted</span>);

    userService.cancelCodeEval();
  }

  function handleDownload() {
    if (workspace) {
      const jsonProject = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(workspace)
      )}`;
      const link = document.createElement("a");
      link.href = jsonProject;
      const proj_file_name = proj.name.split(" ").join("_");
      link.download = proj_file_name + ".json";

      link.click();
    }
  }

  function handleRun() {
    setEvalShown(true);
    refEvalShown = true;

    controller = new AbortController();
    signal = controller.signal;
    const code = genCode();

    setCodeShown(true);

    setEval("");
    setCode("");

    var form = new FormData();
    form.append("code", code);

    var interval = setInterval(() => {
      fetchGpioState();
    }, 500);

    userService.getCodeEval(form, signal).then((json) => {
      clearInterval(interval);
      setEval(json["output"]);
      setGpio(json["gpio"]);
      gpioStateLessObj = json["gpio"];
    });
  }

  function fetchGpioState() {
    userService.getGpioState(signal).then((json) => {
      var result = Object.entries(json["gpio"]).sort(
        (a, b) => a[1][0] - b[1][0]
      );
      console.log("Fetched GPIO", json, result);
      if (json) {
        setGpio(json["gpio"]);
        setGpioKeysSorted(result);
        setEval(json["output"]);
        gpioStateLessObj = json["gpio"];
      }
    });
  }

  function handleNewPinNameChange(e) {
    const newPin = tempPin.map((p, i) => {
      if (i === 1) {
        return e.target.value;
      }
      return p;
    });
    setTempPin(newPin);
  }

  //Vertical Resizing
  useEffect(() => {
    const blocklyAndIOWindowEl = refBlocklyAndIOWindow.current;
    const codeWindowEl = refCodeWindow.current;
    const resizerVertical = refResizerVertical.current;
    if (codeShown) {
      const blocklyAndIOWindowStyle =
        window.getComputedStyle(blocklyAndIOWindowEl);
      const codeWindowStyle = window.getComputedStyle(codeWindowEl);

      let blocklyAndIOWindowHeight = parseInt(
        blocklyAndIOWindowStyle.height,
        10
      );
      let codeWindowHeight = parseInt(codeWindowStyle.height, 10);

      let yCord = 0;

      const onMouseMoveVerticalResize = (event) => {
        const dy = event.clientY - yCord;
        yCord = event.clientY;

        blocklyAndIOWindowHeight = blocklyAndIOWindowHeight + dy;
        codeWindowHeight = codeWindowHeight - dy;
        console.log(
          "mousemove vertical",
          blocklyAndIOWindowHeight,
          codeWindowHeight
        );
        blocklyAndIOWindowEl.style.height = `${blocklyAndIOWindowHeight}px`;
        codeWindowEl.style.height = `${codeWindowHeight}px`;
        onresize();
      };
      const onMouseUpVerticalResize = (event) => {
        document.removeEventListener("mousemove", onMouseMoveVerticalResize);
      };
      const onMouseDownVerticalResize = (event) => {
        yCord = event.clientY;

        blocklyAndIOWindowEl.style.top = blocklyAndIOWindowStyle.top;
        blocklyAndIOWindowEl.style.bottom = null;

        codeWindowEl.style.top = null;
        codeWindowEl.style.bottom = codeWindowStyle.bottom;

        document.addEventListener("mousemove", onMouseMoveVerticalResize);
        document.addEventListener("mouseup", onMouseUpVerticalResize);
      };
      resizerVertical.addEventListener("mousedown", onMouseDownVerticalResize);
      return () => {
        resizerVertical.removeEventListener(
          "mousedown",
          onMouseDownVerticalResize
        );
      };
    } else {
      blocklyAndIOWindowEl.style.height = "100%";
      if (blocklyArea) {
        onresize();
      }
    }
  }, [blocklyArea, codeShown, refBlocklyDiv, onresize]);

  //Horizontal Resizing
  useEffect(() => {
    const blocklyAreaEl = refBlocklyArea.current;
    const iOWindowEl = refIOWindow.current;
    const resizerHorizontal = refResizerHorizontal.current;

    if (blocklyAreaEl !== null && iOWindowEl !== null) {
      const blocklyAreaStyle = window.getComputedStyle(blocklyAreaEl);
      const iOWindowStyle = window.getComputedStyle(iOWindowEl);
      let blocklyAreaWidth = parseInt(blocklyAreaStyle.width, 10);
      let iOWindowWidth = parseInt(iOWindowStyle.width, 10);

      let xCord = 0;
      const onMouseMoveHorizontalResize = (event) => {
        const dx = event.clientX - xCord;
        xCord = event.clientX;

        iOWindowWidth = iOWindowWidth - dx;
        blocklyAreaWidth = blocklyAreaWidth + dx;

        iOWindowEl.style.width = `${iOWindowWidth}px`;
        blocklyAreaEl.style.width = `${blocklyAreaWidth}px`;
        onresize();
      };
      const onMouseUpHorizontalResize = (event) => {
        document.removeEventListener("mousemove", onMouseMoveHorizontalResize);
      };
      const onMouseDownHorizontalResize = (event) => {
        xCord = event.clientX;

        iOWindowEl.style.right = iOWindowStyle.right;
        iOWindowEl.style.left = null;

        blocklyAreaEl.style.right = null;
        blocklyAreaEl.style.left = blocklyAreaStyle.left;

        document.addEventListener("mousemove", onMouseMoveHorizontalResize);
        document.addEventListener("mouseup", onMouseUpHorizontalResize);
      };
      resizerHorizontal.addEventListener(
        "mousedown",
        onMouseDownHorizontalResize
      );
      return () => {
        resizerHorizontal.removeEventListener(
          "mousedown",
          onMouseDownHorizontalResize
        );
      };
    }
  }, [onresize, refBlocklyDiv]);

  // On tab change
  useEffect(() => {
    currentTabStateLessObj = currentTab;
    if (projStateLessObj.length !== 0) {
      // find the index of the current tab
      let tabIndex = projTabData.map((a) => a.tab_id).indexOf(currentTab.id);
      if (tabIndex >= 0) {
        // if the tab is already associated with a workspace then we fetchh that
        const tabData = projTabData[tabIndex];
        var blocks = tabData["workspace"];
        var ids = blocks["ids"];
        setTimeout(() => {
          Blockly.serialization.workspaces.load(
            tabData["workspace"],
            Blockly.getMainWorkspace()
          );
          Blockly.getMainWorkspace().getFlyout().autoClose = false;
          Blockly.getMainWorkspace().getFlyout().getFlyoutScale = function () {
            return 1;
          };
        }, 10);
        setTimeout(() => {
          for (let [key, value] of Blockly.getMainWorkspace().blockDB) {
            if (!ids.includes(value.id)) {
              value.dispose(true);
            }
          }
        }, 2000);

        Blockly.getMainWorkspace().getFlyout().autoClose = false;
        Blockly.getMainWorkspace().getFlyout().getFlyoutScale = function () {
          return 1;
        };
      } else {
        // if a new tab is created
        let data = projTabData;
        data.push({
          tab_id: currentTab.id,
          tab_name: currentTab.name,
          workspace: "",
        });
        projStateLessObj = data;
        setProjTabData(data);
        // Keep track of tabs in this
        const tabs = tabsIds;
        tabs.push([currentTab.id, currentTab.name]);
        setTabsIds(tabs);
        // update project string to the new format
        updateProject(data);
      }
    } else if (currentTab && proj) {
      projStateLessObj = [
        {
          tab_id: currentTab.id,
          tab_name: currentTab.name,
          workspace: JSON.parse(proj["project_str"]),
        },
      ];
      setProjTabData(projStateLessObj);
      // Keep track of tabs in this
      const tabs = tabsIds;
      tabs.push([currentTab.id, currentTab.name]);
      setTabsIds(tabs);
      // update project string to the new format
      updateProject(projStateLessObj);
    }
  }, [currentTab, proj]);

  //Load Workspace
  useEffect(() => {
    if (id !== undefined) {
      if (id.startsWith("default")) {
        console.log(
          "default project detected",
          id.substring(8).replaceAll("_", " ")
        );
        var form = new FormData();
        form.append("name", id.substring(8).replaceAll("_", " "));

        userService.getDefaultProject(form).then((json) => {
          setProj(json);
          setName(json["name"]);
          console.log("project get", json);
          if (json["project_str"] !== "") {
            var project_tab_list = JSON.parse(json["project_str"]);
            projStateLessObj = project_tab_list;
            setProjTabData(project_tab_list);
            setTabsIds(
              project_tab_list.map((value) => [value.tab_id, value.tab_name])
            ); //to send as a prop to EditableTabs component
            var blocks = project_tab_list[0]["workspace"];
            var ids = blocks["ids"];
            Blockly.serialization.workspaces.load(
              project_tab_list[0]["workspace"],
              Blockly.getMainWorkspace()
            );

            setTimeout(() => {
              for (let [key, value] of Blockly.getMainWorkspace().blockDB) {
                // console.log(key, value);
                if (!ids.includes(value.id)) {
                  console.log(value);
                  value.dispose(true);
                }
              }
            }, 2000);

            Blockly.getMainWorkspace().getFlyout().autoClose = false;
            Blockly.getMainWorkspace().getFlyout().getFlyoutScale =
              function () {
                return 1;
              };
          }
        });
      } else {
        var form = new FormData();
        form.append("id", id);

        userService.getProject(form).then((json) => {
          console.log(json);
          setProj(json);
          setName(json["name"]);
          console.log("project get", json);
          if (json["project_str"] !== "") {
            var blocks;
            var ids;
            if (Array.isArray(JSON.parse(json["project_str"]))) {
              console.log(
                "is array i.e. new format",
                JSON.parse(json["project_str"])
              );
              // if the project string has tab structure
              var project_tab_list = JSON.parse(json["project_str"]);
              projStateLessObj = project_tab_list;
              setProjTabData(project_tab_list);
              setTabsIds(
                project_tab_list.map((value) => [value.tab_id, value.tab_name])
              ); //to send as a prop to EditableTabs component

              blocks = project_tab_list[0]["workspace"];
              ids = blocks["ids"];
              Blockly.serialization.workspaces.load(
                project_tab_list[0]["workspace"],
                Blockly.getMainWorkspace()
              );
            } else {
              console.log(
                "is not array i.e. old format",
                JSON.parse(json["project_str"])
              );
              // project string has default structure
              blocks = JSON.parse(json["project_str"]);
              ids = blocks["ids"];

              Blockly.serialization.workspaces.load(
                JSON.parse(json["project_str"]),
                Blockly.getMainWorkspace()
              );
            }

            Blockly.getMainWorkspace().getFlyout().autoClose = false;
            Blockly.getMainWorkspace().getFlyout().getFlyoutScale =
              function () {
                return 1;
              };

            setTimeout(() => {
              for (let [key, value] of Blockly.getMainWorkspace().blockDB) {
                if (!ids.includes(value.id)) {
                  value.dispose(true);
                }
              }
            }, 2000);

            Blockly.getMainWorkspace().getFlyout().autoClose = false;
            Blockly.getMainWorkspace().getFlyout().getFlyoutScale =
              function () {
                return 1;
              };
          }
          fetchGpioState();
          if (json["backpack"] !== "") {
            backpackStateLessObj.setContents(JSON.parse(json["backpack"]));
          }
        });
      }
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <WorkspaceMenu
        user={props.user}
        proj={proj}
        handleDownload={handleDownload}
        handleRun={handleRun}
        handleCodeGen={handleCodeGen}
        handleCancel={handleCancel}
        handleSaveAsNewProject={saveProject}
      />

      <div
        ref={refBlocklyAndIOWindow}
        className="preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-center justify-center gap-2 overflow-y-visible overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full"
        style={{
          backgroundSize: "5px 5px",
          backgroundImage:
            "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)",
          zIndex: 0,
        }}
      >
        {id === undefined && proj === undefined ? (
          <>
            <label htmlFor="create-proj-modal" className="btn">
              Create Project
            </label>

            <input
              type="checkbox"
              id="create-proj-modal"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box">
                <label
                  htmlFor="create-proj-modal"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                >
                  ✕
                </label>
                <h3 className="font-bold text-lg">Create a new project</h3>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <label className="input-group">
                    <span>*</span>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      placeholder="Name"
                      className="input input-bordered"
                    />
                  </label>
                </div>
                <div className="modal-action">
                  <label
                    onClick={() => saveProject()}
                    htmlFor="create-proj-modal"
                    className="btn"
                  >
                    Yay!
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-horizontal w-full h-full">
            <input
              type="checkbox"
              checked={showRenameModal}
              id="rename-gpio"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box ">
                <h3 className="font-bold text-lg">
                  Rename Pin no. {tempPin[0]}
                </h3>
                <button
                  htmlFor="rename-gpio"
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  onClick={() => {
                    setShowRenameModal(false);
                  }}
                >
                  ✕
                </button>
                <div className="flex flex-col mt-20">
                  <input
                    type="text"
                    className="border border-gray-700 p-2 rounded mb-5"
                    placeholder={"Rename Pin " + tempPin[0]}
                    value={tempPin[1]}
                    onChange={(e) => handleNewPinNameChange(e)}
                  />
                </div>
                <div className="modal-action">
                  <button
                    className="btn"
                    onClick={(e) => {
                      e.preventDefault();
                      updateGpio(tempKey, tempPin);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div
              ref={refBlocklyArea}
              id="blocklyArea"
              className="block w-4/5 h-full"
            >
              {/*{ proj != undefined && "foreign_share" in proj ?
    <>*/}
              <EditableTabs
                handleOnInject={handleOnInject}
                handleWorkspaceChange={handleWorkspaceChange}
                xml={xml}
                setXml={setXml}
                tabsIds={tabsIds}
                setCurrentTab={setCurrentTab}
                refBlocklyDiv={refBlocklyDiv}
                read_only={false} //proj.foreign_share}
              />
              {/*    </>
: ""}*/}
              {/* <BlocklyWorkspace
                  className="block  h-full"
                  onWorkspaceChange={(e) => handleWorkspaceChange(e)}
                  onInject={(e) => handleOnInject(e)}
                  workspaceConfiguration={{
                    zoom: {
                      controls: true,
                      wheel: true,
                      startScale: 1,
                      maxScale: 3,
                      minScale: 0.3,
                      scaleSpeed: 1.2,
                    },
                  }}
                  toolboxConfiguration={Toolbox}
                  initialXml={xml}
                  onXmlChange={setXml}
                /> */}
              {/* </div> */}
            </div>
            <div
              ref={refResizerHorizontal}
              className="resizerHorizontal h-full w-1.5"
              draggable="true"
            ></div>
            <div ref={refIOWindow} className="overflow-scroll w-1/5">
              <div className="tabs">
                <button
                  className={
                    `tab tab-lifted ` + (tabs.gpio ? "tab-active" : "")
                  }
                  onClick={() =>
                    setTabs({ gpio: true, variables: false, timers: false })
                  }
                >
                  GPIO
                </button>
                <button
                  className={
                    `tab tab-lifted ` + (tabs.variables ? "tab-active" : "")
                  }
                  onClick={() =>
                    setTabs({ gpio: false, variables: true, timers: false })
                  }
                >
                  Variables
                </button>
                <button
                  className={
                    `tab tab-lifted ` + (tabs.timers ? "tab-active" : "")
                  }
                  onClick={() =>
                    setTabs({ gpio: false, variables: false, timers: true })
                  }
                >
                  Timers
                </button>
              </div>
              <div
                className={`overflow-x-auto ` + (!tabs.gpio ? "hidden" : "")}
              >
                <select className="select select-primary select-sm w-full max-w-xs mt-2.5">
                  <option disabled>Pick device for I/O</option>
                  <option selected>2ABCB-RPI4B</option>
                </select>

                <table className="table table-compact w-full">
                  <thead>
                    {" "}
                    <tr>
                      {" "}
                      <th>PIN</th>{" "}
                      <th className="flex justify-between items-center">
                        GPIO
                        <div>
                          {" "}
                          <button
                            className="btn btn-ghost"
                            onClick={(e) => {
                              e.preventDefault();
                              const setValue = !isGpioTabNotLock;
                              setIsGpioTabNotLock(setValue);
                              showToastMessage(
                                setValue
                                  ? "GPIO tab is unlocked"
                                  : "GPIO tab is locked",
                                setValue ? "success" : "error"
                              );
                            }}
                          >
                            <div>
                              <Swap
                                className="mx-1"
                                active={isGpioTabNotLock} // active by default
                                onElement={
                                  <div className="swap-on text-success">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-unlock"
                                    >
                                      <rect
                                        x="3"
                                        y="11"
                                        width="18"
                                        height="11"
                                        rx="2"
                                        ry="2"
                                      ></rect>
                                      <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
                                    </svg>
                                  </div>
                                }
                                offElement={
                                  <div className="swap-off text-error">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-lock"
                                    >
                                      <rect
                                        x="3"
                                        y="11"
                                        width="18"
                                        height="11"
                                        rx="2"
                                        ry="2"
                                      ></rect>
                                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                    </svg>
                                  </div>
                                }
                              />
                            </div>
                          </button>
                        </div>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {gpioKeysSorted.map(([k, pin]) => (
                      <tr key={k}>
                        <th>{pin[0]}</th>
                        {!pin[5] ? (
                          <td className="flex justify-between items-center">
                            {pin[1]}
                          </td>
                        ) : (
                          <td className="flex justify-between items-center">
                            <button
                              className="btn btn-xs btn-ghost"
                              onClick={(e) => {
                                e.preventDefault();
                                if (isGpioTabNotLock) {
                                  setTempKey(k);
                                  setTempPin(pin);
                                  setShowRenameModal(true);
                                }
                              }}
                            >
                              {pin[1]}
                            </button>
                            <div className="flex content-center items-center">
                              {pin[4] === "A" ? (
                                <button
                                  disabled={!isGpioTabNotLock}
                                  className="btn-xs text-right"
                                  style={{ width: "80px", paddingRight: "0px" }}
                                  /*onClick={handleAnalogEditClick}*/
                                >
                                  <input
                                    className="input input-xs text-right w-full max-w-xs"
                                    type="number"
                                    style={{ paddingRight: "0px" }}
                                    defaultValue={23}
                                    disabled={!isGpioTabNotLock}
                                  />
                                </button>
                              ) : (
                                <label
                                  class={`swap mx-1 ${
                                    pin[2] ? "swap-active" : ""
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (isGpioTabNotLock) {
                                        pin[2] = pin[2] ? false : true;
                                        updateGpio(k, pin);
                                      }
                                    }}
                                  />
                                  <div className="swap-on text-success">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-power"
                                    >
                                      <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                      <line
                                        x1="12"
                                        y1="2"
                                        x2="12"
                                        y2="12"
                                      ></line>
                                    </svg>
                                  </div>
                                  <div className="swap-off text-error">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-power"
                                    >
                                      <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                      <line
                                        x1="12"
                                        y1="2"
                                        x2="12"
                                        y2="12"
                                      ></line>
                                    </svg>
                                  </div>
                                </label>
                              )}
                              <label
                                class={`swap mx-1 ${
                                  pin[3] == "in" ? "swap-active" : ""
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (isGpioTabNotLock) {
                                      pin[3] = pin[3] === "in" ? "out" : "in";
                                      updateGpio(k, pin);
                                    }
                                  }}
                                />
                                <div className="swap-on text-info">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-log-out"
                                  >
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                  </svg>
                                </div>
                                <div className="swap-off text-secondary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-log-in"
                                  >
                                    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                                    <polyline points="10 17 15 12 10 7"></polyline>
                                    <line x1="15" y1="12" x2="3" y2="12"></line>
                                  </svg>
                                </div>
                              </label>

                              <label
                                class={`swap mx-1 ${
                                  pin[4] == "D" ? "swap-active" : ""
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (isGpioTabNotLock) {
                                      pin[4] = pin[4] == "D" ? "A" : "D";
                                      updateGpio(k, pin);
                                    }
                                  }}
                                />
                                <div className="swap-on text-info">D</div>
                                <div className="swap-off text-secondary">A</div>
                              </label>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className={
                  `overflow-x-auto ` + (!tabs.variables ? "hidden" : "")
                }
              >
                <table className="table table-compact w-full">
                  <thead>
                    <tr>
                      <th>Sr #</th>
                      <th>Variable</th>
                      <th>Type</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variableList.map(function (variable, index) {
                      return (
                        <tr key={variable.var_id}>
                          <td>{index + 1}</td>
                          <td>{variable.name}</td>
                          <td>{variable.type}</td>
                          <td onDoubleClick={handleOnEditVarValueDoubleClick}>
                            {editVarValue ? (
                              <input
                                defaultValue={variable.value}
                                onBlur={(e) =>
                                  handleOnEditVarValueBlur(e, variable.var_id)
                                }
                                onChange={handleEditVarValue}
                              />
                            ) : (
                              <div>{variable.value}</div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div
                className={`overflow-x-auto ` + (!tabs.timers ? "hidden" : "")}
              >
                <table className="table table-compact w-full">
                  <thead>
                    <tr>
                      <th>Sr #</th>
                      <th>Timer</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timerList.map(function (timer, index) {
                      return (
                        <tr key={timer.var_id}>
                          <td>{index + 1}</td>
                          <td>{timer.name}</td>
                          <td>{timer.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {codeShown ? (
        <>
          <div
            ref={refResizerVertical}
            className="resizerVertical h-1.5 w-full"
            draggable="true"
          ></div>
          <div
            ref={refCodeWindow}
            className="preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-96"
          >
            <button
              onClick={() => {
                handleCancel();
                setCodeShown(false);
              }}
              className="btn btn-sm"
              style={{ position: "absolute", right: "20px" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>

            <pre className="text-left">
              {eval_ == "" ? (
                <>
                  &#62;
                  <br />
                </>
              ) : (
                ""
              )}
              {code}
              {eval_ ? (
                <>
                  <br />
                  &#62;
                  <br />
                  {eval_}
                </>
              ) : (
                ""
              )}
            </pre>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default WorkspaceReact;
