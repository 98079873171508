import React, { useEffect } from "react";
// import { ThemeContext, themes } from "./Theme/theme_context";
// import ToggleTheme from "./Theme/toggle_theme";
import WorkspaceMenu from "./WorkspaceMenu.js";
import { themeChange } from "theme-change";

const Settings = (props) => {
  useEffect(() => {
    themeChange(false);
  });
  return (
    <>
      <WorkspaceMenu user={props.user} />

      <div
        className="preview border-base-300 bg-base-200 rounded-b-box rounded-tr-box flex min-h-[6rem] min-w-[18rem] flex-wrap items-center justify-center gap-2 overflow-x-hidden border bg-cover bg-top p-4 undefined w-full h-full"
        style={{
          backgroundSize: "5px 5px",
          backgroundImage:
            "radial-gradient(hsla(var(--bc)/.2) .5px,hsla(var(--b2)/1) .5px)",
          zIndex: 0,
        }}
      >
        <h2 className="card-title">Change theme dark/light</h2>
        <button
          data-toggle-theme="business,corporate"
          data-act-class="ACTIVECLASS"
        >
          <input type="checkbox" className="toggle toggle-lg" />
        </button>
      </div>
    </>
  );
};
export default Settings;
