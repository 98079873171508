import React, { useState } from "react";

const SaveAsNewProjectModal = (props) => {
  const [projName, setProjName] = useState(props.initialProjName);

  function handleNewNameChange(e) {
    setProjName(e.target.value);
  }

  function handleModalClose() {
    setProjName("");
    props.handleModalClose();
  }
  function handleSaveAsNewProject() {
    props.handleSaveAsNewProject(true, projName);
    handleModalClose();
  }
  return (
    <>
      <input
        type="checkbox"
        checked={props.showModal}
        id="rename-gpio"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box ">
          <h3 className="font-bold text-lg">Save Project As</h3>
          <button
            htmlFor="rename-gpio"
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={handleModalClose}
          >
            ✕
          </button>
          <div className="flex flex-col mt-20">
            <input
              type="text"
              className={"border border-gray-700 p-2 rounded mb-5"}
              placeholder="Enter Name For Project"
              value={projName}
              onChange={(e) => handleNewNameChange(e)}
            />
          </div>
          <div className="modal-action">
            <button
              className="btn"
              // eslint-disable-next-line eqeqeq
              disabled={projName == ""}
              onClick={handleSaveAsNewProject}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SaveAsNewProjectModal;
